import React, {Fragment, useEffect, useRef, useState} from 'react'
import axios, * as others from 'axios';
import {useLoaderData, useLocation, useParams} from "react-router-dom";
import {
    addEventAttribute,
    audienceButtons,
    changeAttribute,
    getAttribute,
    getBestImage,
    getImageWithPrefix,
    getLocalDate,
    hasAttribute,
    imagePad, manageAttractionData,
    MetaTags,
    setAttribute,
    setRoomAttribute,
    setUniqueAttractionAttribute,
    setUniqueEventAttribute,
    smallEventCard,
    thumbnailPad,
    toastSuccess
} from "../../components/common/utilities";
import 'react-dropzone-uploader/dist/styles.css'
import {ToastContainer} from "react-toastify";
import MediaCollection from "../media/mediaCollection";

function classNames(...classes: any[])
{
    return classes.filter(Boolean).join(' ')
}

export default function CreateArtist() {
    let params = useParams();
    const venueID = params.venueID;
    const data = useLoaderData() as any;
    //console.log(data);

    type SchemaAttraction = {
        [key: string]: any; // ?? variable key
        name: string;
    };

    const text = useRef("");
    const inFocus = useRef("");
    const [attractionData, setAttractionData] = useState<SchemaAttraction>();
    const [events, setEvents] = useState([]);
    const [photos, setPhotos] = useState([]);
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#ffffff");
    const [open, setOpen] = useState(false);
    const [showAI, setShowAI] = useState(false);
    const [showMediaSelector, setShowMediaSelector] = useState(false);
    const [attractionName, setAttractionName] = useState("");

    useEffect(() => {
        //getArtist();
        //getEvents("");
        //getPhotos();

    }, []);
    const now = new Date();

    const getArtist = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/p/api/artist.do?`, {

            tiny: true, artist: params.id, admin: true, ticket: localStorage.getItem('ticket'),bust: now.toUTCString()
        }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }

        }).then(function (response) {
            // handle success
            //console.log(response.data);
            setAttractionData(response.data)
            setAttractionName(response.data.name);
        }).catch(function (error) {
            // handle error
            console.log(error);
        });
    }

    const createArtist = (e) => {
        e.preventDefault();
        console.log(e);

        console.log('posting ', attractionData);
        axios.post(`${process.env.REACT_APP_API_URL}/p/manage/performer/create.do`, attractionData, {}).then(function (response) {
            // handle success
            console.log(response);
            setAttractionData(response.data);
            //setOpen(false);
            //setRooms(response.data.collection);
            //window.location.href = '/onboarding/room/'+response.data.collection[0]['guid'];

        });
    }

    function clickedEvent(e)
    {
        console.log("clicked", e.target);

        let button = e.target.dataset.button;
        if(button === 'setAd')
        {
            console.log('setAd');
            let key = e.target.dataset.key;
            let value = e.target.dataset.value;
            let unique = e.target.dataset.unique;
            let remove = e.target.dataset.remove;
            let eventid = e.target.dataset.eventid;

            setUniqueEventAttribute(key,value,unique,remove,eventid);

            toastSuccess('Event updated');

        }
        else if(button === 'setPrivate')
        {
            console.log('setting private');
            let key = e.target.dataset.key;
            let value = e.target.dataset.value;
            let unique = e.target.dataset.unique;
            let remove = e.target.dataset.remove;
            let eventid = e.target.dataset.eventid;

            setUniqueEventAttribute(key,value,unique,remove,eventid);

            if(value === 'true')
            {
                toastSuccess('Event set to public');
            }
            else {
                toastSuccess('Event set to private');
            }

        }
        else if(button === 'viewEvent')
        {
            let eventid = e.target.dataset.eventid;
            //viewEvent(null,eventid);
        }
        else if(button === 'setAttractionType')
        {
            console.log('setting private');
            let key = e.target.dataset.key;
            let value = e.target.dataset.value;
            let unique = e.target.dataset.unique;
            let remove = e.target.dataset.remove;
            let eventid = e.target.dataset.eventid;

            setUniqueEventAttribute(key,value,unique,remove,eventid);

            toastSuccess('Attraction Type set to '+value);

        }

        else if(button === 'addAttribute')
        {
            console.log('setting private');
            let key = e.target.dataset.key;
            let value = e.target.dataset.value;
            let unique = false;
            let remove = e.target.dataset.remove;
            let eventid = e.target.dataset.eventid;

            addEventAttribute(key,value,unique,eventid);

            toastSuccess('Attribute '+key+' set to '+value);

        }

        else if(button === 'manageAudience')
        {
            console.log('managing audience');
            let key = e.target.dataset.key;
            let value = e.target.dataset.value;
            let unique = true;
            let remove = e.target.dataset.remove;
            let eventid = e.target.dataset.eventid;

            setUniqueAttractionAttribute('pgg.audience.'+key,'pgg.audience.'+value,unique,remove,eventid);

            toastSuccess('Audience set '+key+' Remove: '+remove);

        }
        else if(button === 'setDescription')
        {
            console.log('setting description');
            let key = "description-html-en";
            let value = e.target.dataset.description;
            let unique = true;
            let remove = false;
            let eventid = params.id;

            setUniqueAttractionAttribute(key,value,unique,remove,eventid);

            toastSuccess('Description set '+key+' Remove: '+remove);

        }

        if (button === 'mediaSelected')
        {
            console.log('settingMedia');
            let media = e.target.dataset.media;
            setAttractionData({...attractionData, image: media});
            setShowMediaSelector(false);

            //toastSuccess('Set photo');

        }

        if (button === 'setTempArtistImage')
        {
            console.log('settingMedia');
            let media = e.target.dataset.media;
            setAttractionData({...attractionData, image: media});
            setShowMediaSelector(false);

            //toastSuccess('Set photo');

        }

    }

    const changeName = e => {
        e.preventDefault();
        console.log(attractionName);
        setAttractionData({...attractionData, name: attractionName});
        //toastSuccess('Saved');
    }


    const getEvents = nextOffset => {
        axios.get(`${process.env.REACT_APP_API_URL}/p/events/upcoming.do`, {
            params: {
                instance: 'promogogo', ticket: localStorage.getItem('ticket'), //guid: params.id,
                limit: 12, offset: nextOffset, artist: params.id, light: true,
            }

        }).then(function (response) {
            // handle success
            //console.log("events", response);
            setEvents(response.data.collection);

        });
    }

    const showMedia = () => {
        setShowAI(false);
        setShowMediaSelector(true);
        setOpen(true);
    }

    const showAIprompt = () => {
        setShowAI(true);
        setShowMediaSelector(false);
        setOpen(true);
    }

    const clear = () => {
        setAttractionData(null);
        setAttractionName('');
        var form = document.getElementById('createArtistForm') as HTMLFormElement;
        form.value.value = '';
    }







    return (

        <div className="min-h-full">

            <div className="mt-2">

                <main>

                    <div className="">
                        <div className="">
                            <div className="">
                                <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">

                                    <div className="px-4 py-5 sm:p-6">
                                        <div className=" px-4 sm:mt-16 sm:px-0 lg:mt-0">
                                            <div className="text-2xl text-gray-500">Create Artist</div>
                                            <div className="grid grid-cols-3">
                                                <div>
                                                    <div className="col-span-1 min-h-32 bg-gray-100 flex items-end"
                                                         onClick={() => showMedia()}>

                                                        {attractionData && attractionData.image &&
                                                         <div>{imagePad(attractionData.image)}</div>}


                                                    </div>
                                                    <div>
                                                        <button
                                                            onClick={() => showMedia()}
                                                            className="relative inline-flex items-center rounded bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">Find
                                                            Image
                                                        </button>
                                                    </div>
                                                </div>


                                                <div className="col-span-2 m-2">
                                                    <form onSubmit={changeName} id={'createArtistForm'}>
                                                        <label htmlFor="value"
                                                               className="text-1xl font-bold tracking-tight text-gray-900">
                                                        Name
                                                        </label>
                                                        <div className="mt-2 flex rounded-md shadow-sm">
                                                            <div
                                                                className="relative flex flex-grow items-stretch focus-within:z-10">
                                                                <div
                                                                    className="p-2 pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">

                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    name="value"
                                                                    id="value"
                                                                    className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-2 ring-1 ring-inset ring-gray-300  focus:ring-2 focus:ring-inset focus:ring-indigo-600  text-3xl font-bold tracking-tight text-gray-900"
                                                                    defaultValue={attractionName}
                                                                    onChange={event => setAttractionName(event.target.value)}
                                                                />
                                                            </div>
                                                            <button
                                                                type="submit"
                                                                className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                            >

                                                                Set
                                                            </button>
                                                        </div>

                                                    </form>
                                                    {attractionData && attractionData.description && (<>
                                                        <p className={'mt-2 p-2'}
                                                           dangerouslySetInnerHTML={{__html: attractionData.description}}></p>
                                                    </>)}
                                                </div>
                                            </div>
                                            <div className={'mt-2'} onClick={(e) => {
                                                clickedEvent(e)
                                            }}>
                                                {showMediaSelector && <MediaCollection wide={true} target={'setTempArtistImage'}/>}
                                            </div>
                                            {attractionData && !attractionData['@id'] && <>
                                                <div className={'mt-4 isolate inline-flex rounded-md shadow-sm'}>
                                                    <button
                                                            className={'relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'}
                                                            onClick={(e) => createArtist(e)}
                                                    >Create

                                                    </button>

                                                </div>
                                            </>}
                                            {attractionData && attractionData['@id'] && <>
                                                <div className={'mt-4 isolate inline-flex rounded-md shadow-sm'}>
                                                    <button
                                                            className={'relative inline-flex items-center rounded bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'}
                                                            onClick={(e) =>  window.open('/attraction/' + attractionData['@id'], '_blank')}
                                                    >Continue

                                                    </button>
                                                    <button
                                                            className={'ml-2 relative inline-flex items-center rounded bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'}
                                                            onClick={(e) => clear()}
                                                    >Create another

                                                    </button>

                                                </div>
                                            </>}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </div>
            <ToastContainer autoClose={800} theme={'dark'}/>

        </div>)
}
