import React, {Fragment, useEffect, useState} from 'react'
import {Dialog, Disclosure, Menu, Transition} from '@headlessui/react'
import {Bars3Icon, BellIcon, XMarkIcon} from '@heroicons/react/24/outline'

import PropertyNavigation from "../../components/common/navigation.component";
import MainNavigation from "../../components/common/navigation.component";
import SidebarNavigation from "../../components/common/sidebarnavigation.component";
import {Link, useLoaderData, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {isIDInArray, isIndexInArray, MetaTags, smallEventCard} from "../../components/common/utilities";
import {ChevronDownIcon, MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";
import FindVenues from "../venues/findVenues";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import FindPerformer from "../performers/findPerformers";
import MediaCollection from "../media/mediaCollection";
import {SchemaEvent} from "../../components/common/dataTypes";
import CreateEvent from "../events/createEvent";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

function classNames(...classes: any[])
{
    return classes.filter(Boolean).join(' ')
}

export default function NLPDashboard() {
    const navigate = useNavigate();
    const logout = () => {
        window.location.href = '/logout';
    }

    let params = useParams();
    const venueID = params.venueID;
    const loaderdata = useLoaderData() as any;
    console.log(loaderdata);
    const today = new Date();
    const dateString = today.toISOString().split('T')[0];

    const [events, setEvents] = useState([]);
    const [edp, setEDP] = useState([]);
    const [eventOffset, setEventOffset] = useState(0);
    const [eventLimit, setEventLimit] = useState(10);
    const [NLPStatus, setNLPStatus] = useState([]);
    const [NLPUrl, setNLPUrl] = useState([]);
    const [NLPData, setNLPData] = useState([]);
    const [NLPEvent, setNLPEvent] = useState<SchemaEvent>();
    const [imageToNLP, setImageToNLP] = useState("");
    const [textForNLP, setTextForNLP] = useState("");
    const [URLForEvent, setURLForEvent] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [createForm, setCreateForm] = useState(true);
    const [wideMedia, setWideMedia] = useState(true);

    var date = new Date();
    const getEvents = nextOffset => {
        axios.get(`${process.env.REACT_APP_API_URL}/go/promogogo/timeline.do?`, {
            params: {
                limit: 10, offset: nextOffset, json: 1, filter: 'EVENT', date: date.toISOString(),
            }

        }).then(function (response) {
            // handle success
            console.log("events", response);
            setEvents(response.data.moments);
            setEventOffset(eventOffset + eventLimit);
        });
    }

    const getNLP = (url): any => {
        axios.get(url).then(function (response) {
            // handle success
            //console.log("NLPdata", response.data);
            if (response.data.status)
            {
                setNLPStatus(response.data.status);
            }
            if (response.data.status === 'SUCCESS' && response.data.result)
            {
                setLoading(false);
                setCount(0);
                setNLPStatus([]);
                console.info("NLPData", response.data.result);
                if (response.data.type === 'imgToText')
                {
                    console.log('imgtotext', response.data.result);
                    toggleNLPImage();
                    setTextForNLP(response.data.result.description);
                    toggleNLPText();
                }
                else if (response.data.type === 'imgToEvent')
                {
                    console.log('imgtoevent', response.data.result);
                    setImageToNLP(response.data.result.input);
                    toggleNLPImage()
                    if (response.data.result.result.events)
                    {
                        console.log('imgtoevent events', response.data.result.result.events);
                        setNLPData(response.data.result.result.events);
                        let event = response.data.result.result.events[0];
                        console.log('event', event);
                        viewEvent(event);
                    }
                    else
                    {
                        setNLPEvent(response.data.result.events);
                        let event = response.data.result.events[0];
                        console.log('event', event);
                        viewEvent(event);
                    }
                    //toggleNLPText();
                }
                else if (response.data.type === 'passthrough')
                {
                    console.log('passthrough', response.data.result);
                    let eventresult = response.data.result;
                    if (eventresult.startsWith('```json\n'))
                    {
                        eventresult = eventresult.replaceAll('```json\n', '');
                        eventresult = eventresult.replaceAll('```', '');
                        console.log(eventresult);
                        let event = JSON.parse(eventresult);
                        viewEvent(event);

                    }
                    //toggleNLPText();
                }
                else if (response.data.type === 'event')
                {
                    if (response.data.result.event)
                    {
                        setNLPData(response.data.result.event);
                        let event = response.data.result.event[0];
                        console.log('event', event);
                        viewEvent(event);
                    }
                    if (response.data.result.events)
                    {
                        setNLPData(response.data.result.events);
                        let event = response.data.result.events[0];
                        console.log('event', event);
                        viewEvent(event);
                    }

                }
                else if (response.data.result.events)
                {
                    setNLPData(response.data.result.events);
                    let event = response.data.result.events[0];
                    console.log('event', event);
                    viewEvent(event);
                }
                else
                {
                    setNLPData([]);
                }
            }

            else if (response.data.status === 'FAILURE')
            {
                setLoading(false);
                setCount(0);
                setError(response.data.result);
                localStorage.removeItem('nlpurl');
            }
            else
            {
                setLoading(true);
                pollNLP(url);
            }

        });

    }

    const refreshNLP = () => {
        getNLP(NLPUrl);
    }

    const [count, setCount] = useState(0);

    const pollNLP = (url) => {
        setCount(count + 1);
        setTimeout(() => getNLP(url), 5000)
    }

    const splitEvent = (event) => {
        event.preventDefault();
        let theText = event.target.elements.text.value;
        if(theText.startsWith('{'))
        {
            let event = JSON.parse(theText);
            viewEvent(event);
        }
        else {
            axios.post(`https://translator.promogogo.com/nlp/event`, {
                text: event.target.elements.text.value
            }, {
                           headers: {
                               'instance': loaderdata.instance, 'face-uri': loaderdata.url,
                           },
                       }).then(function (response) {
                // handle success
                //console.log("NLP Split", response.data);
                if (response.data.url)
                {
                    setNLPUrl(response.data.url);
                    localStorage.setItem('nlpurl', response.data.url);
                    getNLP(response.data.url);
                }

            }).catch(function (error) {
                // handle error
                console.log(error);

            });
        }

    }

    const getEventFromURL = (event) => {
        event.preventDefault()
        let URL = URLForEvent;
        console.log(URL);
        let prompt = "Using information from " + URL + " give me a json document in Schema.org Format with event information in icelandic, putting the organizer and instructor as performers and offers as a list of offers. Return only the json document.";
        axios.post(`https://translator.promogogo.com/nlp/chat`, {
            system: "", assistant: "", prompt: prompt
        }, {
                       headers: {
                           'instance': loaderdata.instance, 'face-uri': loaderdata.url,
                       },
                   }).then(function (response) {
            // handle success
            //console.log("NLP Split", response.data);
            if (response.data.url)
            {
                setNLPUrl(response.data.url);
                localStorage.setItem('nlpurl', response.data.url);
                getNLP(response.data.url);
            }

        }).catch(function (error) {
            // handle error
            console.log(error);

        });
    }

    const getEventsFromImage = (imageurl) => {
        axios.post(`https://translator.promogogo.com/nlp/imgtoevent`, {
            url: imageurl
        }, {
                       headers: {
                           'instance': loaderdata.instance, 'face-uri': loaderdata.url,
                       },
                   }).then(function (response) {
            // handle success
            //console.log("NLP Split", response.data);
            if (response.data.url)
            {
                setNLPUrl(response.data.url);
                localStorage.setItem('nlpurl', response.data.url);
                getNLP(response.data.url);
            }

        }).catch(function (error) {
            // handle error
            console.log(error);

        });
    }

    const getTextFromImage = (imageurl) => {
        axios.post(`https://translator.promogogo.com/nlp/imgtotext`, {
            url: imageurl
        }, {
                       headers: {
                           'instance': loaderdata.instance, 'face-uri': loaderdata.url,
                       },
                   }).then(function (response) {
            // handle success
            //console.log("NLP Split", response.data);
            if (response.data.url)
            {
                setNLPUrl(response.data.url);
                localStorage.setItem('nlpurl', response.data.url);
                getNLP(response.data.url);
            }

        }).catch(function (error) {
            // handle error
            console.log(error);

        });
    }

    const getMoreEvents = () => {
        getEvents(eventOffset);
    }

    const clearNLP = () => {
        setNLPData([]);
        setNLPUrl([]);
        setTextForNLP("");
        setImageToNLP("");
        setNLPEvent(null);
        setNLPStatus([]);
        localStorage.removeItem('nlpurl');
    }

    useEffect(() => {

        isMobile ? setWideMedia(false) : setWideMedia(true);

        //getEvents("");
        if (localStorage.getItem('nlpurl'))
        {
            getNLP(localStorage.getItem('nlpurl'));
        }
        //getPhotos();

    }, []);

    // EDIT EVENT DIALOG

    const [open, setOpen] = useState(false);

    type SchemaEvent = {
        [key: string]: any; // ?? variable key
        name: string;
    };
    type SchemaLocation = {
        [key: string]: any; // ?? variable key
        name: string;
    };

    const [eventData, setEventData] = useState<SchemaEvent>();
    const [venueData, setVenueData] = useState<SchemaLocation>();
    const [performers, setPerformers] = useState([]);
    const [performerIndex, setPerformerIndex] = useState([]);
    const [showMedia, setShowMedia] = useState(false);
    const [nlpText, setNLPText] = useState(true);
    const [nlpImage, setNLPImage] = useState(false);
    const [nlpURL, setNLPURL] = useState(false);

    const toggleNLPText = () => {
        setNLPText(true);
        setNLPImage(false);
        setNLPURL(false);

    }
    const toggleNLPImage = () => {
        setNLPText(false);
        setNLPImage(!nlpImage);
        setNLPURL(false);

    }
    const toggleNLPURL = () => {
        setNLPText(false);
        setNLPImage(false);
        setNLPURL(true);

    }


    const createEvent = (e) => {
        e.preventDefault();
        console.log(e);
        if (performers && performers.length > 0)
        {
            setEventData({
                             ...eventData, performers

                         })
        }


        console.log('posting ', eventData);
        axios.post(`${process.env.REACT_APP_API_URL}/p/api/event/create.do`, eventData, {
            headers: {
                'ticket': localStorage.getItem('ticket'),
            }
        }).then(function (response) {
            // handle success
            console.log(response);
            setEventData(response.data);
            //setOpen(false);
            //setRooms(response.data.collection);
            //window.location.href = '/onboarding/room/'+response.data.collection[0]['guid'];

        });
    }

    const viewEvent = (event) => {
        console.log(event);
        setPerformers([]);
        setPerformerIndex([]);
        event['@id'] = '';
        event.localStartDate = event.startDate;
        event.localEndDate = event.endDate;
        if (!event.localEndDate)
        {
            const end = new Date(event.startDate);
            end.setHours(end.getHours() + 2);
            event.localEndDate = end.toISOString();
        }

        if (imageToNLP)
        {
            event.image = imageToNLP;
        }

        if (event.performer)
        {
            event.performers = event.performer;
        }

        setEventData(event);
        console.log("event", eventData);
        setVenueData(event.location);
        setShowMedia(false);

        setOpen(true);
    }

    const loadJson = () => {

        let event = JSON.parse(textForNLP);
        setPerformers([]);
        setPerformerIndex([]);
        event['@id'] = '';
        event.localStartDate = event.startDate;
        event.localEndDate = event.endDate;
        if (!event.localEndDate)
        {
            const end = new Date(event.startDate);
            end.setHours(end.getHours() + 2);
            event.localEndDate = end.toISOString();
        }

        if (imageToNLP)
        {
            event.image = imageToNLP;
        }

        if (event.performer)
        {
            event.performers = event.performer;
        }

        setEventData(event);
        console.log("event", eventData);
        setVenueData(event.location);
        setShowMedia(false);

        setOpen(true);
    }

    const changeName = e => {
        e.preventDefault();
        alert('change name');
        /*axios.post(`${process.env.REACT_APP_API_URL}/keepsapi/api/createroom.do`, {
            instance: 'keeps',
            ticket: localStorage.getItem('ticket'),
            property: data['guid'],
            title: roomtitle,
            description: roomdescription,
        }, {
                       headers: {
                           'Content-Type': 'application/x-www-form-urlencoded'
                       }
                   }).then(function (response) {
            // handle success
            console.log(response);
            //setRooms(response.data.collection);
            window.location.href = '/onboarding/room/'+response.data.collection[0]['guid'];

        });*/
    }

    function changeDescription(e)
    {
        setEventData({
                         ...eventData, description: e.target.value
                     })
        console.log('description added to ', eventData);
    }

    function changeStartDate(e)
    {
        //console.log(e);
        console.log(moment.utc(e._d).format('YYYY-MM-DDTHH:mm:ss'));
        let start = moment.utc(e._d).format('YYYY-MM-DDTHH:mm:ss');
        setEventData({
                         ...eventData, localStartDate: start, startDate: start
                     })
    }

    function changeEndDate(e)
    {
        console.log(e);
        console.log(moment.utc(e._d).format('YYYY-MM-DDTHH:mm:ss'));
        let end = moment.utc(e._d).format('YYYY-MM-DDTHH:mm:ss');
        setEventData({
                         ...eventData, localEndDate: end, endDate: end
                     })
    }

    function getAddress(e)
    {
        e.preventDefault();
        const formElement = document.getElementById("address-form");
        if (formElement)
        {
            const formData = new FormData(formElement as HTMLFormElement);
            const data = Object.fromEntries(formData.entries());
            console.log("Form Data:", data);
        }
        else
        {
            console.error("No form with the specified ID found.");
        }
    }

    function setVenue(e)
    {
        console.log("venue", e.target);
        let venueid = e.target.dataset.venueid;
        if (venueid !== undefined)
        {
            console.log(`Event bubbled up to div from `, venueid);
            //console.log(eventData);
            let venuename = e.target.dataset.venuename;
            let venueimage = e.target.dataset.venueimage;

            var location = eventData.location;
            location.name = venuename;
            location.image = venueimage;
            location['@id'] = venueid;

            setVenueData(location);

            setEventData({
                             ...eventData, location

                         })
            console.log(eventData);

        }


    }

    function setImage(e)
    {
        console.log("venue", e.target);
        let venueid = e.target.dataset.venueid;
        let image = e.target.dataset.media;
        if (image !== undefined)
        {
            console.log(`Event bubbled up to div from `, image);
            //console.log(eventData);

            setEventData({
                             ...eventData, image: image

                         })
            console.log(eventData);
            setShowMedia(false);
        }


    }

    function processImage(e)
    {
        console.log("process image", e);
        let image = e.target.dataset.media;
        if (image !== undefined)
        {
            console.log(`Event bubbled up to div from `, image);
            setImageToNLP(image);
            setNLPImage(false);
            //console.log(eventData);
        }


    }


    function addPerformer(e, index)
    {
        console.log("performer", e.target);
        let performerid = e.target.dataset.performerid;
        let performerindex = index;
        if (performerid !== undefined)
        {
            console.log(`Event bubbled up to div from `, performerid);
            if (isIDInArray(performers, performerid))
            {
                console.log(performerid + ' already in array');
                return;
            }
            //console.log(eventData);
            let performername = e.target.dataset.performername;
            let performerimage = e.target.dataset.performerimage;

            var performer = eventData.performers[performerindex];
            if (!performer)
            {
                console.log('tried to get performer', performerindex, eventData.performers);
            }
            else
            {
                performer.name = performername;
                performer.image = performerimage;
                performer['@id'] = performerid;

                setPerformers( // Replace the state
                               [ // with a new array
                                   ...performers, // that contains all the old items
                                   performer // and one new item at the end
                               ]);
                setPerformerIndex( // Replace the state
                                   [ // with a new array
                                       ...performerIndex, // that contains all the old items
                                       {index: index} // and one new item at the end
                                   ]);

                console.log('performers', performers);
            }


            //setVenueData(location);

            /*setEventData({
                             ...eventData,
                             location

                         })
            console.log(eventData);*/

        }


    }

    return (<>
        <MetaTags
            title={'Creating events'}
            description={'Add data yourself or have our elves do it for you.'}
        />
        <div className="min-h-full bg-white dark:bg-slate-800">
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <div className="fixed inset-0"/>

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-2">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                                        <div
                                            className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                            <div
                                                className="flex min-h-0 flex-1 flex-col overflow-y-scroll overflow-x-hidden py-1">
                                                <div className="px-2 sm:px-6">
                                                    <div className="flex items-start justify-between">
                                                        <Dialog.Title
                                                            className="text-base font-semibold leading-6 text-gray-900">
                                                            Create Event
                                                        </Dialog.Title>
                                                        <div className="ml-3 flex h-7 items-center">
                                                            <button
                                                                type="button"
                                                                className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                                onClick={() => setOpen(false)}
                                                            >
                                                                <span className="absolute -inset-2.5"/>
                                                                <span className="sr-only">Close panel</span>
                                                                <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                                    <div onClick={(e) => setShowMedia(true)}>
                                                        {eventData && eventData.image &&
                                                         <div className="h-48 bg-gray-100 rounded-lg overflow-hidden">
                                                             <img src={'https://promogogo.com/cdn-cgi/image/width=600,height=400,fit=pad,gravity=auto/' + eventData.image}/>
                                                         </div>}
                                                        {eventData && !eventData.image &&
                                                         <div className="h-48 bg-gray-100 rounded-lg overflow-hidden">
                                                         </div>}
                                                    </div>
                                                    {showMedia && <div>
                                                        <div className="flex items-start justify-between">
                                                            <Dialog.Title
                                                                    className="text-base font-semibold leading-6 text-gray-900">
                                                                Media collection
                                                            </Dialog.Title>
                                                            <div className="ml-3 flex h-7 items-center">
                                                                <button
                                                                        type="button"
                                                                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                                                        onClick={() => setShowMedia(false)}
                                                                >
                                                                    <span className="absolute -inset-2.5"/>
                                                                    <span className="sr-only">Close panel</span>
                                                                    <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div onClick={(event) => setImage(event)}>
                                                            <MediaCollection wide={wideMedia}
                                                                             target={'mediaSelected'}/></div>
                                                    </div>}
                                                    {eventData && <div className="px-4 py-5 sm:p-6">
                                                        <div className=" px-1 sm:mt-1 sm:px-0 lg:mt-0">

                                                            <form onSubmit={changeName}>
                                                                {/*<label htmlFor="value" className="text-1xl font-bold tracking-tight text-gray-900">
                                                Title
                                            </label>*/}
                                                                <div className="mt-2 flex rounded-md shadow-sm h-10">
                                                                    <div
                                                                            className="relative flex flex-grow items-stretch focus-within:z-10">
                                                                        <div
                                                                                className="p-2 pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">

                                                                        </div>
                                                                        <input
                                                                                type="text"
                                                                                name="value"
                                                                                id="value"
                                                                                className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-2 ring-1 ring-inset ring-gray-300  focus:ring-2 focus:ring-inset focus:ring-indigo-600  text-3xl font-bold tracking-tight text-gray-900"
                                                                                defaultValue={eventData.name}
                                                                        />
                                                                    </div>
                                                                    <button
                                                                            type="submit"
                                                                            className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                                    >

                                                                        Save
                                                                    </button>
                                                                </div>

                                                            </form>


                                                        </div>
                                                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2">

                                                            <div className=" px-4 mt-8 sm:px-0 ">
                                                                <LocalizationProvider dateAdapter={AdapterMoment}
                                                                                      adapterLocale="en-gb">
                                                                    <DateTimePicker
                                                                            label="From"
                                                                            defaultValue={moment(eventData.localStartDate)}
                                                                            ampm={false}
                                                                            onChange={changeStartDate}
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                            <div className=" px-4 mt-8 sm:px-0">
                                                                <LocalizationProvider dateAdapter={AdapterMoment}
                                                                                      adapterLocale="en-gb">
                                                                    <DateTimePicker
                                                                            label="Until"
                                                                            defaultValue={moment(eventData.endDate)}
                                                                            ampm={false}
                                                                            onChange={changeEndDate}
                                                                    />
                                                                </LocalizationProvider>
                                                            </div>
                                                        </div>
                                                        <div className=" px-4 sm:mt-16 sm:px-0 lg:mt-3">

                                                            <label htmlFor="description"
                                                                   className="block text-sm font-medium leading-6 text-gray-900">
                                                                Lýsing
                                                            </label>
                                                            <textarea rows={5}
                                                                      id="description"
                                                                      className="block w-full resize-none border-gray-200 py-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                                      defaultValue={eventData.description}
                                                                      onChange={changeDescription}
                                                            >


                                            </textarea>

                                                            <section aria-labelledby="details-heading"
                                                                     className="mt-12">
                                                                {performers && performers.length > 0 &&
                                                                 <h5 className="text-sm font-bold mb-2">Fram koma</h5>}
                                                                {performers && performers.length > 0 && performers.map((performer, index) => (
                                                                    <div className="sm:flex" key={'performing' + index}>
                                                                        <div
                                                                            className="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
                                                                            {performer.image && <img
                                                                                    src={performer.image}
                                                                                    alt={performer.name}
                                                                                    className="h-16 w-16 rounded-lg"/>}
                                                                            {!performer.image && <svg
                                                                                    className="h-16 w-16 border border-gray-300 bg-white text-gray-300"
                                                                                    preserveAspectRatio="none"
                                                                                    stroke="currentColor"
                                                                                    fill="none"
                                                                                    viewBox="0 0 200 200"
                                                                                    aria-hidden="true"
                                                                            >
                                                                                <path vectorEffect="non-scaling-stroke"
                                                                                      strokeWidth={1}
                                                                                      d="M0 0l200 200M0 200L200 0"/>
                                                                            </svg>}
                                                                        </div>
                                                                        <div>
                                                                            <h4 className="text-lg font-bold">{performer.name}</h4>
                                                                            <p className="mt-1">
                                                                                {performer.description}
                                                                            </p>
                                                                        </div>
                                                                    </div>))}

                                                                {eventData.performers && eventData.performers.length > 0 &&
                                                                 <div className={'mt-2 text-2xl font-bold'}>Performers</div>}

                                                                {eventData.performers && eventData.performers.length > 0 && eventData.performers.map((performer, index) => (<>
                                                                    {!isIndexInArray(performerIndex, index) &&
                                                                     <div key={'performers' + index} className="mt-4">
                                                                         <div onClick={(event) => addPerformer(event, index)}>
                                                                             <FindPerformer venueData={performer}/>
                                                                         </div>
                                                                     </div>}</>))}


                                                                {venueData && venueData['@id'] &&
                                                                 <div className="sm:flex mt-3">
                                                                     <div className="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
                                                                         {venueData.image && <img
                                                                                 src={venueData.image}
                                                                                 alt={venueData.name}
                                                                                 className="h-16 w-16 rounded-lg"/>}
                                                                         {!venueData.image && <svg
                                                                                 className="h-16 w-16 border border-gray-300 bg-white text-gray-300"
                                                                                 preserveAspectRatio="none"
                                                                                 stroke="currentColor"
                                                                                 fill="none"
                                                                                 viewBox="0 0 200 200"
                                                                                 aria-hidden="true"
                                                                         >
                                                                             <path vectorEffect="non-scaling-stroke"
                                                                                   strokeWidth={1}
                                                                                   d="M0 0l200 200M0 200L200 0"/>
                                                                         </svg>}
                                                                     </div>
                                                                     <div>
                                                                         <h4 className="text-lg font-bold">{venueData.name}</h4>
                                                                         <p className="mt-1">
                                                                             {venueData.description}
                                                                         </p>
                                                                     </div>
                                                                 </div>}
                                                                {!venueData || venueData && !venueData['@id'] && <>
                                                                    <div className={'mt-2 text-2xl font-bold'}>Venue</div>
                                                                    <div onClick={setVenue}>
                                                                        <FindVenues venueData={venueData} limit={20}
                                                                                    merge={false}/>
                                                                    </div>
                                                                </>}


                                                                <div>
                                                                    {eventData && eventData['@id'] &&
                                                                     <div className="mt-4">
                                                                         <Link to={'/event/' + eventData['@id']}
                                                                               target={'_blank'}
                                                                               className={'block rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'}>Edit</Link>
                                                                     </div>}

                                                                    <div
                                                                            className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">


                                                                    </div>
                                                                    <div className="px-4 sm:px-6 lg:px-8 mt-2">
                                                                        <div className="sm:flex sm:items-center">
                                                                            <div className="sm:flex-auto">


                                                                            </div>
                                                                            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">

                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </section>

                                                        </div>
                                                    </div>}

                                                </div>
                                            </div>
                                            <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                                <button
                                                    type="button"
                                                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                                                    onClick={() => setOpen(false)}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    onClick={createEvent}
                                                    className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <div className={'p-1 md:p-2'}>

                <main className="">
                    <div className="px-2 sm:px-6 lg:px-8">
                        <span className={'text-2xl font-medium tracking-tight text-gray-900 mb-2'}>Creating events using {createForm ? 'a form' : 'magic'} </span>
                        <div className="mt-2">
                            <button
                                //type="button"
                                onClick={() => createForm ? setCreateForm(false) : setCreateForm(true)}
                                className="text-sm font-semibold text-pg_blue  hover:bg-gray-50 focus:z-10"
                            >
                                Create using {createForm ? 'Magic' : 'Event form'}
                            </button>

                        </div>
                        {createForm && <CreateEvent/>}
                        {!createForm &&  <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow p-1 md:p-4 mt-2">

                            {/*<h3 className={'text-2xl font-medium tracking-tight text-gray-900 mb-2'}>Magic Dashboard</h3>
                            <p className={'tracking-tight text-gray-900 mb-2'}>Get the AI-Elves to help with adding information</p>*/}
                            <span className="isolate inline-flex rounded-md ">
      <button
              type="button"
              onClick={() => toggleNLPText()}
              className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
      >
        Events from text
      </button>
      <button
              type="button"
              onClick={() => toggleNLPImage()}

              className="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
      >
        Events from image
      </button>
                         <button
                                 type="button"
                                 onClick={() => toggleNLPURL()}

                                 className="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                         >
        Events from web
      </button>
      <button
              type="button"
              onClick={() => clearNLP()}
              className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
      >
        Clear
      </button>
    </span>
                            <div className="">

                                <div className="">
                                    <div className={'p-2'}>
                                        {imageToNLP && <>
                                            <div className={'cursor-pointer'}
                                                 onClick={() => getTextFromImage(imageToNLP)}>
                                                <img
                                                        src={imageToNLP}/></div>

                                            <div className="isolate inline-flex rounded-md shadow-sm">
                                                <button
                                                        type="button"
                                                        onClick={() => getEventsFromImage(imageToNLP)}
                                                        className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                                                >
                                                    Event from image
                                                </button>
                                                <button
                                                        type="button"
                                                        onClick={() => getTextFromImage(imageToNLP)}

                                                        className="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                                                >
                                                    Text from image
                                                </button>

                                            </div>
                                        </>}


                                    </div>

                                    {nlpText && <form className="" onSubmit={splitEvent}>
                                        <label htmlFor="splitevent"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Paste information about one or more events. The clearer the text the better the results.
                                        </label>
                                        <textarea
                                                id="splitevent"
                                                className="block w-full p-2 border rounded-md my-2 resize-none border-gray-400 py-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 h-96 font-mono"
                                                placeholder="Text to split into events..."
                                                name="text"
                                                value={textForNLP}
                                                onChange={(e) => setTextForNLP(e.target.value)}
                                        />
                                        {/*<div className={'mt-4'}>
                                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
                                                <DatePicker
                                                        label="Date"
                                                        name={'date'}
                                                        defaultValue={moment(dateString)}
                                                />
                                            </LocalizationProvider>
                                        </div>*/}
                                        <div className="mt-2">
                                            <div className="p-2 grid grid-cols-2">
                                                <div className={'grid-cols-1'}>
                                                    <button type={'submit'}
                                                            className={'block rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'}>
                                                        Find events in text
                                                    </button>

                                                </div>
                                                <div className={'grid-cols-1 ml-1'}>

                                                    {NLPUrl.length > 0 && <button onClick={refreshNLP}
                                                                                  className={'block rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'}>Refresh</button>}
                                                </div>
                                            </div>

                                        </div>


                                    </form>}
                                    {nlpURL && <form className="" onSubmit={getEventFromURL}>
                                        <label htmlFor="splitevent"
                                               className="block text-2xl font-medium leading-6 text-gray-900">
                                            URL
                                        </label>
                                        <input type={'text'}
                                               id="splitevent"
                                               className="block w-full p-2 border rounded-md my-2 resize-none border-gray-400 py-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 text-xl"
                                               placeholder="URL of the page to extract events from..."
                                               name="text"
                                               defaultValue={textForNLP}
                                               onChange={(e) => setURLForEvent(e.target.value)}
                                        />
                                        <div className="mt-2">
                                            <div className="p-2 grid grid-cols-2">
                                                <div className={'grid-cols-1'}>
                                                    <button type={'submit'}
                                                            className={'block rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'}>
                                                        Set Event
                                                    </button>

                                                </div>
                                                <div className={'grid-cols-1 ml-1'}>

                                                    {NLPUrl.length > 0 && <button onClick={refreshNLP}
                                                                                  className={'block rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'}>Refresh</button>}
                                                </div>
                                            </div>

                                        </div>


                                    </form>}


                                    {loading && <div className="p-4 text-gray-800 flex justify-center">
                                        <div className={'text-pg_yellow'}><i
                                                className="fa-2x fa-duotone fa-loader fa-spin"></i> {NLPStatus}</div>
                                    </div>}

                                    <div className="pt-2">
                                        {nlpImage && <div className={'p-2'} onClick={(e) => processImage(e)}>
                                            <MediaCollection wide={wideMedia} target={'mediaSelected'}/>
                                        </div>}
                                        {/*<button onClick={getAddress}
                                                className={'block rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'}>Stofna
                                        </button>*/}
                                    </div>

                                </div>
                                <div>
                                    <div className={'mt-2 text-2xl font-bold'}>Events</div>
                                    {NLPData.map((event, index) => (<div key={'nlp' + index}
                                                                         className={'p-2 border border-gray-400 rounded-md my-2'}>
                                        <div className={'font-bold'}>{event.name}</div>
                                        <div>{(new Date(event.startDate)).toLocaleString("is")}</div>
                                        <div>{event.location && event.location.name}</div>

                                        <div className={'p-2 text-gray-900'}>{event.description}</div>
                                        <div className={'flex justify-end'}>
                                            <button onClick={() => viewEvent(event)}
                                                    className={'block rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'}>Stofna
                                            </button>
                                        </div>
                                    </div>))}
                                    {NLPEvent && <div className={'p-2 border border-gray-400 rounded-md my-2'}>
                                        <div className={'font-bold'}>{NLPEvent.name}</div>
                                        <div>{(new Date(NLPEvent.startDate)).toLocaleString("is")}</div>
                                        <div>{NLPEvent.location && NLPEvent.location.name}</div>

                                        <div className={'p-2 text-gray-900'}>{NLPEvent.description}</div>
                                        <div className={'flex justify-end'}>
                                            <button onClick={() => viewEvent(NLPEvent)}
                                                    className={'block rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'}>Stofna
                                            </button>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>}
                    </div>

                </main>
            </div>
        </div>


    </>)
}
