/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, {Fragment, useEffect, useRef, useState} from 'react'
import {Dialog, Menu, Transition,Popover, PopoverButton, PopoverPanel} from '@headlessui/react'
import {
    Bars3Icon,
    BellIcon,
    CalendarIcon,
    ChartPieIcon,
    Cog6ToothIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
    WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline'
import {ChevronDownIcon, MagnifyingGlassIcon} from '@heroicons/react/20/solid'
import {Navigate, NavLink, useLoaderData, useLocation} from "react-router-dom";
import axios from "axios";
import {fastLinkedSearchCard, fastSearchCard, isUrlValid, smallEventCard} from "./utilities";

function classNames(...classes)
{
    return classes.filter(Boolean).join(' ')
}

export default function SidebarNavigation() {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const loaderdata = useLoaderData() as any;
    const location = useLocation();
    const [addingEvent, setAddingEvent] = useState(false)
    const [searchResults, setSearchResults] = useState<any[]>([])
    const [searching, setSearching] = useState(false);
    const [cancel, setCancel] = useState(null);
    const [theNav, setTheNav] = useState<any[]>([{
        name: 'Vikan', href: '/dashboard', icon: HomeIcon, current: false
    }, {name: 'Auglýsingar', href: '/admarket', icon: HomeIcon, current: false},
                                                    {
                                                        name: 'Create Events', href: '/events/create', icon: WrenchScrewdriverIcon, current: false
                                                    },
                                                    {name: 'User Dashboard', href: '/manage/user', icon: HomeIcon, current: false},
                                                    {name: 'Útskrá', href: '/logout', icon: HomeIcon, current: false},])
    const [adminNav, setAdminNav] = useState<any[]>([{
        name: 'Import', href: '/import', icon: CalendarIcon, current: false
    }, {
        name: 'Admin Events', href: '/adminevents', icon: CalendarIcon, current: false
    }, {
        name: 'Admin Actions', href: '/admin/actions', icon: CalendarIcon, current: false
    }, {
        name: 'Venues', href: '/venues', icon: CalendarIcon, current: false
    }, {
        name: 'Attractions', href: '/attractions', icon: CalendarIcon, current: false
    },                                                     {name: 'Viðburðir', href: '/events', icon: CalendarIcon, current: false},
                                                    ])
    const [importSidebarString, setImportSidebarString] = useState('');

    const data = useLoaderData() as any;
    if (data)
    {
        if (data.name !== localStorage.getItem('username'))
        {
            localStorage.setItem('username', data.name);
        }
    }

    let cancelToken;

    const handlePaste = event => {
        var eventurl = event.clipboardData.getData('text');
        setImportSidebarString(eventurl);
        setAddingEvent(true)
        if (!isUrlValid(eventurl))
        {
            alert("Invalid URL");
            setImportSidebarString('')
            setAddingEvent(false)
            return;
        }
        else
        {
            axios.post(`${process.env.REACT_APP_API_URL}/p/api/event/import.do`, {
                eventurl: eventurl, ticket: localStorage.getItem('ticket')
            }, {
                           headers: {
                               'Content-Type': 'application/x-www-form-urlencoded'
                           }
                       }).then(function (response) {
                // handle success
                setAddingEvent(false)
                setImportSidebarString('')
                console.log('importSidebarString', importSidebarString)
            }).catch(function (error) {
                // handle error
                console.log(error);
                setAddingEvent(false)

            });
        }
    };


    function picknav()
    {
        console.log('location.pathname', location.pathname);
        if (data.isAdmin)
        {
            setTheNav(adminNav)
        }


    }

    useEffect(() => {


    }, []);


    const addEvent = (event) => {
        event.preventDefault()
        setAddingEvent(true)
        if (!isUrlValid(event.target.elements.text.value))
        {
            alert("Invalid URL");
            setImportSidebarString('')
            setAddingEvent(false)
            return;
        }
        else
        {
            axios.post(`${process.env.REACT_APP_API_URL}/p/api/event/import.do`, {
                eventurl: event.target.elements.text.value, ticket: localStorage.getItem('ticket')
            }, {
                           headers: {
                               'Content-Type': 'application/x-www-form-urlencoded'
                           }
                       }).then(function (response) {
                // handle success
                setImportSidebarString('')
                setAddingEvent(false)
            }).catch(function (error) {
                // handle error
                console.log(error);
                setAddingEvent(false)

            });
        }

    }

    const clearSearch = (e) => {
        e.preventDefault()
        setSearchResults([])
        let searchfield = document.getElementById('search-field') as HTMLInputElement
        searchfield.value = ''
    }

    const handleSearchChange = async (e) => {
        setSearchResults([])
        const searchTerm = e.target.value
        if (searchTerm.length === 0)
        {
            setSearchResults([])
            setSearching(false)
            return;
        }


        //Check if there are any previous pending requests

        if (cancel)
        {
            cancel.cancel("Operation canceled due to new request.")
        }

        cancelToken = axios.CancelToken.source()
        setCancel(cancelToken);
        setSearching(true)

        try
        {

            const results = await axios.get(`${process.env.REACT_APP_API_URL}/p/ajax/search.do?event=true&limit=25&q=${searchTerm}`,

                                            {cancelToken: cancelToken.token} //Pass the cancel token to the current request

            )
            setSearching(false)
            setSearchResults(results.data.collection);

            console.log("Results for " + searchTerm + ": ", results.data)

        }
        catch (error)
        {

            console.log(error)

        }
    }


    return (<>
        <header
            className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8 md:ml-40">
            <Transition.Root show={!sidebarOpen} as={Fragment}>
                <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                        onClick={() => setSidebarOpen(true)}>
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                </button>
            </Transition.Root>
            <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true"/>

            <div className="mx-auto max-w-7xl px-6 mt-4 self-stretch">
                <form className="relative flex flex-1" action="#" method="GET">
                    <label htmlFor="search-field" className="sr-only">
                        Search
                    </label>
                    <MagnifyingGlassIcon
                        className="ml-2 pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                        aria-hidden="true"
                    />
                    <input
                        id="search-field"
                        className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Search..."
                        type="search"
                        name="search"
                        onKeyUp={e => handleSearchChange(e)}
                    />
                    <button
                        type="button"
                        className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-red-700 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={(e) => clearSearch(e)}
                    >
                        <XMarkIcon aria-hidden="true" className="-ml-0.5 h-5 w-5 text-red-700"/>
                    </button>

                </form>
            </div>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h6 className="font-bold leading-tight tracking-tight">{loaderdata.name}</h6>
            </div>

            <div id="search-results"
                 className="absolute top-full left-0 w-full bg-white shadow-lg z-50 border rounded">
                {searching && (<div className="p-4 text-gray-800 flex justify-center">
                    <div className={'text-pg_yellow'}><i
                        className="fa-2x fa-duotone fa-loader fa-spin"></i></div>
                </div>)}
                {searchResults && searchResults.length > 0 && (<ul className={'mt-2 md:ml-72 max-w-3xl p-2 z-30 max-h-[70vh] overflow-auto'}>
                    {searchResults.map((result) => (<li className={'mt-1'}>{fastLinkedSearchCard(result)}</li>))}
                </ul>)}
            </div>

        </header>



        <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-900/80"/>
                </Transition.Child>

                <div className="fixed inset-0 flex">
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                    <button type="button" className="-m-2.5 p-2.5"
                                            onClick={() => setSidebarOpen(false)}>
                                        <span className="sr-only">Close sidebar</span>
                                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                                    </button>
                                </div>
                            </Transition.Child>
                            {/* Sidebar component, swap this element with another sidebar if you like */}
                            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-pg_blue px-6 pb-4">
                                <div className="flex h-16 shrink-0 items-center">
                                    <img
                                        className="h-8 w-auto"
                                        src="https://cached.promogogo.com/styles/promogogo/promo_heart_yellow_256px.png"
                                        alt="Promogogo"
                                    />
                                </div>
                                <nav className="flex flex-1 flex-col">
                                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                        <ul role="list" className="-mx-2 space-y-1">
                                            {theNav.map((item) => <li
                                                className={'group flex gap-x-3 rounded-md text-sm leading-6 font-semibold'}
                                                key={item.name}
                                            >
                                                <NavLink
                                                    to={item.href}
                                                    className={({isActive}) => (isActive ? "p-2 inline-flex items-center gap-x-1.5 shrink-0 bg-pg_purple text-white w-full " : "p-2 inline-flex items-center gap-x-1.5 w-full shrink-0 text-white hover:text-white hover:bg-pg_blue")}
                                                >
                                                    <item.icon
                                                        className={classNames(item.current ? 'text-white' : 'text-indigo-200 group-hover:text-white', 'h-6 w-6 shrink-0')}
                                                        aria-hidden="true"
                                                    />
                                                    {item.name}
                                                </NavLink></li>)}
                                        </ul>
                                        {data.isAdmin && <li>
                                            <h5 className="text-sm font-semibold text-gray-100 uppercase tracking-wider">Admin</h5>
                                            <ul role="list" className="-mx-2 space-y-1">
                                                {adminNav.map((item) => <li
                                                    className={'group flex gap-x-3 rounded-md text-sm leading-6 font-semibold'}
                                                    key={item.name}>
                                                    <NavLink
                                                        to={item.href}
                                                        className={({isActive}) => (isActive ? "p-2 inline-flex items-center gap-x-1.5 shrink-0 bg-pg_purple text-white w-full " : "p-2 inline-flex items-center gap-x-1.5 w-full shrink-0 text-white hover:text-white hover:bg-pg_blue")}
                                                    >
                                                        <item.icon
                                                            className={classNames(item.current ? 'text-white' : 'text-indigo-200 group-hover:text-white', 'h-6 w-6 shrink-0')}
                                                            aria-hidden="true"
                                                        />
                                                        {item.name}
                                                    </NavLink></li>)}
                                            </ul>
                                        </li>}
                                        <li className="mt-auto">
                                            <div>
                                                <a
                                                    href="#"
                                                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-pg_blue hover:text-white"
                                                >
                                                    <Cog6ToothIcon
                                                        className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                                                        aria-hidden="true"
                                                    />
                                                    Settings
                                                </a>
                                            </div>
                                            <div className={'mt-2'}>
                                                <a
                                                    href="/manage/user"
                                                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-pg_blue hover:text-white"
                                                >
                                                    <Cog6ToothIcon
                                                        className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                                                        aria-hidden="true"
                                                    />
                                                    Your dashboard
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-pg_purple px-6 pb-4">
                <div className="flex h-16 shrink-0 items-center">
                    <a href={'/'}>
                        <img
                            className="h-8 w-auto"
                            src="https://cached.promogogo.com/styles/promogogo/promo_heart_yellow_256px.png"
                            alt="Promogogo"
                        /> </a>
                </div>
                <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                            <ul role="list" className="-mx-2 space-y-1">
                                {theNav.map((item) => <li
                                    className={'group flex gap-x-3 rounded-md text-sm leading-6 font-semibold'}
                                    key={item.name}><NavLink
                                    to={item.href}
                                    className={({isActive}) => (isActive ? "p-2 inline-flex items-center gap-x-1.5 shrink-0 bg-pg_blue text-white w-full " : "p-2 inline-flex items-center gap-x-1.5 w-full shrink-0 text-white hover:text-white hover:bg-pg_blue")}
                                >
                                    <item.icon
                                        className={classNames(item.current ? 'text-white' : 'text-indigo-200 group-hover:text-white', 'h-6 w-6 shrink-0')}
                                        aria-hidden="true"
                                    />
                                    {item.name}
                                </NavLink></li>)}
                            </ul>
                            {/*<ul role="list" className="-mx-2 space-y-1">

                                {theNav.map((item) => (<li key={item.name}>
                                    <a
                                        href={item.href}
                                        className={classNames(item.current ? 'bg-pg_blue text-white' : 'text-indigo-200 hover:text-white hover:bg-pg_blue', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold')}
                                    >
                                        <item.icon
                                            className={classNames(item.current ? 'text-white' : 'text-indigo-200 group-hover:text-white', 'h-6 w-6 shrink-0')}
                                            aria-hidden="true"
                                        />
                                        {item.name}
                                    </a>
                                </li>))}
                            </ul>*/}
                        </li>
                        <li>
                            <div>
                                <div>
                                    <form onSubmit={addEvent} id={'import-full'}>
                                        <label htmlFor="text"
                                               className="block text-sm font-medium leading-6 text-gray-100">
                                            Import event
                                        </label>
                                        <div className="mt-2 flex rounded-md shadow-sm">
                                            <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                                <input
                                                    value={importSidebarString}
                                                    onChange={e => setImportSidebarString(e.target.value)}
                                                    type="text"
                                                    name="text"
                                                    id="input-full"
                                                    className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    placeholder="https://..."
                                                    onPaste={pasteEvent => handlePaste(pasteEvent)}
                                                />
                                            </div>
                                            <button
                                                type="submit"
                                                className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 bg-white ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                            >
                                                {addingEvent &&
                                                 <i className="fa-solid fa-circle-notch fa-spin"></i>}{!addingEvent &&
                                                                                                       <span>Add</span>}
                                            </button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </li>
                        {data.isAdmin && <li>
                            <h5 className="text-sm font-semibold text-gray-100 uppercase tracking-wider">Admin</h5>
                            <ul role="list" className="-mx-2 space-y-1">
                                {adminNav.map((item) => <li
                                    className={'group flex gap-x-3 rounded-md text-sm leading-6 font-semibold'}
                                    key={item.name}
                                ><NavLink
                                    to={item.href}
                                    className={({isActive}) => (isActive ? "p-2 inline-flex items-center gap-x-1.5 shrink-0 bg-pg_blue text-white w-full " : "p-2 inline-flex items-center gap-x-1.5 w-full shrink-0 text-white hover:text-white hover:bg-pg_blue")}
                                >
                                    <item.icon
                                        className={classNames(item.current ? 'text-white' : 'text-indigo-200 group-hover:text-white', 'h-6 w-6 shrink-0')}
                                        aria-hidden="true"
                                    />
                                    {item.name}
                                </NavLink></li>)}
                            </ul>
                        </li>}
                        <li className="mt-auto">
                            <div>
                                <a
                                    href="/logout"
                                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-pg_blue hover:text-white"
                                >
                                    <Cog6ToothIcon
                                        className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                                        aria-hidden="true"
                                    />
                                    Logout
                                </a>
                            </div>
                            <div className={'mt-2'}>
                                <a
                                    href="/manage/user"
                                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-pg_blue hover:text-white"
                                >
                                    <Cog6ToothIcon
                                        className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                                        aria-hidden="true"
                                    />
                                    Your dashboard
                                </a>
                            </div>
                        </li>

                    </ul>
                </nav>
            </div>
        </div>
    </>)
}
