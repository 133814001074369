import React, {Fragment, useEffect, useState} from 'react'
import {Disclosure, RadioGroup, Tab, Menu, Transition} from '@headlessui/react'
import {
    BellIcon, StarIcon, FolderOpenIcon, PencilSquareIcon, CheckIcon, ChevronRightIcon
} from '@heroicons/react/20/solid'
import {HeartIcon, MinusIcon, PlusIcon, XMarkIcon, Bars3Icon} from '@heroicons/react/24/outline'
import {findVenue} from "../../components/search/VenueSearch";
import axios from "axios";
import {Link, useLocation} from "react-router-dom";
import PropertyNavigation from "../../components/common/navigation.component";
import {getLocalDate} from "../../components/common/utilities";
import {ArrowLongLeftIcon, ArrowLongRightIcon, ChevronDoubleLeftIcon} from "@heroicons/react/16/solid";

import Dropzone from "react-dropzone-uploader";
import {render} from "@testing-library/react";
import { getDroppedOrSelectedFiles } from 'html5-file-selector'

function classNames(...classes: any[])
{
    return classes.filter(Boolean).join(' ')
}

export default function MediaCollection({wide,target}) {
    const [mediaList, setMediaList] = useState<any[]>([])
    const location = useLocation();
    const [searchingVenue, setSearchingVenue] = useState(false);
    const [totalSize, setTotalSize] = useState(0);
    const [loading, setLoading] = useState(false);
    const [nextOffset, setNextOffset] = useState('');
    const [previousOffset, setPreviousOffset] = useState('');
    const [query, setQuery] = useState('');
    const [cancel, setCancel] = useState(null);
    const [nothingFound, setNothingFound] = useState(false);
    const [firstImage, setFirstImage] = useState('');

    let cancelToken;

    useEffect(() => {

        //getEvents("");
        getMedia("", "",false);
        //getPhotos();

    }, []);

    function mediaName(e: any)
    {
        console.log(e.target.value);
        const query = e.currentTarget.value;
        if (query)
        {
            setQuery(query);
            getMedia("", query,false);
        }
    }

    const getMedia = (offset,search,uploading:any) => {

        if (cancel)
        {
            cancel.cancel("Operation canceled due to new request.")
        }

        cancelToken = axios.CancelToken.source()
        setCancel(cancelToken);

        if(!search)
        {
            let lastsearch = localStorage.getItem('lastsearch');
            if(lastsearch)
            {
                search = lastsearch;
                let theinput = document.getElementById('media_name') as HTMLInputElement;
                theinput.value = lastsearch;
            }
        }

        let icon = document.querySelector('.refreshMedia');
        if (icon)
        {
            icon.classList.add('fa-spin');
        }

        let limit = 20;
        if (!wide)
        {
            limit = 8;
        }
        setSearchingVenue(true);
        setNothingFound(false);
        axios.post(`${process.env.REACT_APP_API_URL}/p/manage/listmedia.do`, {

            ticket: localStorage.getItem('ticket'),
            importmedia: 'uploads',
            limit: limit,
            offset: offset,
            searchValue: search,
            searchKey: 'by-keyword'
        }, {
                       cancelToken: cancelToken.token, headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
                   })
            .then(function (response) {
                // handle success
                //console.log(response);
                localStorage.setItem('lastsearch', search);
                let location = response.data;
                //console.log(location);
                if(uploading)
                {
                    if(location.collection[0])
                    {
                        console.log("comparing "+location.collection[0].displayURL+" to "+firstImage);
                        if(location.collection[0].displayURL === firstImage)
                        {
                            console.log('same image');
                            setTimeout(() => {getMedia("","",true)}, 3000)

                        }
                        else {
                            console.log('new image');
                            setSearchingVenue(false);
                            setMediaList(location.collection);
                            if(!offset)
                            {
                                if(location.collection[0])
                                {
                                    setFirstImage(location.collection[0].displayURL);
                                    console.log(location.collection[0].displayURL);
                                }
                            }

                            setTotalSize(response.data.pagination.totalSize)
                            setNextOffset(response.data.pagination.nextOffset)
                            setPreviousOffset(response.data.pagination.previousOffset)
                            if (response.data.pagination.totalSize === 0)
                            {
                                setNothingFound(true);
                            }
                            if(icon)
                            {
                                icon.classList.remove('fa-spin');
                            }
                            return location;
                        }
                    }
                }
                else {
                    setSearchingVenue(false);
                    setMediaList(location.collection);
                    if(!offset)
                    {
                        if(location.collection[0])
                        {
                            setFirstImage(location.collection[0].displayURL);
                            console.log(location.collection[0].displayURL);
                        }
                    }

                    setTotalSize(response.data.pagination.totalSize)
                    setNextOffset(response.data.pagination.nextOffset)
                    setPreviousOffset(response.data.pagination.previousOffset)
                    if (response.data.pagination.totalSize === 0)
                    {
                        setNothingFound(true);
                    }
                    if(icon)
                    {
                        icon.classList.remove('fa-spin');
                    }
                    return location;

                }


            })
            .catch(function (error) {
                // handle error
                console.log(error);
                return error;
            })
            .finally(function () {
                return 'done';
            });


    }

    const clearMedia = () => {
        let theinput = document.getElementById('media_name') as HTMLInputElement;
        theinput.value = '';
        localStorage.setItem('lastsearch', '');
        setQuery('');
        getMedia("","",false);
    }


    const MediaUploader: any = () => {
        // specify upload params and url for your files
        const getUploadParams = ({meta}) => {
            return {
                url: `${process.env.REACT_APP_API_URL}/p/manage/media/upload.do`,
                fields: {instance: 'promogogo', ticket: localStorage.getItem('ticket')}
            }
        }

        // called every time a file's `status` changes
        const handleChangeStatus = ({meta, file}, status) => {
            console.log(status, meta, file)
            if (status === 'done')
            {
                getMedia("", "",true);
            }


        }


        // receives array of files that are done uploading when submit button is clicked
        const handleSubmit = (files, allFiles) => {
            console.log("handle submit:",files.map(f => f.meta))
            allFiles.forEach(f => f.remove())
            setTimeout(() => {getMedia("","",true)}, 3000)
        }

        const getFilesFromEvent = e => {
            return new Promise(resolve => {
                getDroppedOrSelectedFiles(e).then(chosenFiles => {
                    resolve(chosenFiles.map(f => f.fileObject))
                })
            })
        }

        return (<Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            onSubmit={handleSubmit}
            autoUpload={true}
            accept="image/*,audio/*,video/*"
            inputContent="Drop your photos here or click to browse"
            submitButtonContent={'Upload'}


            styles={{
                dropzone: {
                    border: '4px dashed', borderColor: 'lightgray', borderRadius: 5, backgroundColor: '#fafafa'
                }, inputLabel: {marginRight: 10, marginLeft: 10},
            }}
        />)
    }


    return (

        <div className="min-h-full">
            <div className="bg-white ">
                <div className={'mt-2'}>
                    <MediaUploader/>
                </div>

                <div className="mx-auto mt-6 max-w-2xl leading-8">
                    <div
                        className="flex rounded-l-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                        <input
                            type="text"
                            name="media_name"
                            id="media_name"
                            className="block flex-1 border-0 bg-transparent  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 p-2"
                            placeholder="Image search"
                            defaultValue={''}
                            onChange={mediaName}
                        />
                        <button
                            className="block flex-1 border-0 bg-transparent  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 p-2"
                            onClick={() => clearMedia()}>Clear
                        </button>
                    </div>


                    {mediaList && mediaList.length > 0 &&
                     <p className="mt-2 text-sm text-gray-500" id="email-description">
                         {totalSize > 0 && totalSize} images.
                     </p>}

                </div>

                <div className="mt-2">
                    {mediaList && mediaList.length > 0 &&
                     <nav className="sticky bg-white z-10 flex items-center justify-between px-4 sm:px-0  mt-2 mb-2"
                          style={{top: "60px"}}>
                         <div className="-mt-px flex w-0 flex-1">
                             <div
                                     onClick={() => getMedia("", query,false)}
                                     className="cursor-pointer inline-flex items-center border-b-2 border-transparent pr-1 pb-2 pt-2 text-sm font-medium text-gray-500 hover:border-blue-500 hover:text-gray-700"
                             >
                                 <ChevronDoubleLeftIcon className="mr-3 h-5 w-5 text-green-800" aria-hidden="true"/>

                             </div>
                         </div>
                         <div className="-mt-px md:flex">
                             <div
                                     onClick={() => getMedia(previousOffset, query,false)}
                                     className="cursor-pointer inline-flex items-center border-b-2 border-transparent pr-1 pb-2 pt-2 text-sm font-medium text-gray-500 hover:border-blue-500 hover:text-gray-700"
                             >
                                 <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-green-800" aria-hidden="true"/>

                             </div>
                             <div
                                     onClick={() => getMedia(nextOffset, query,false)}
                                     className="cursor-pointer inline-flex items-center border-b-2 border-transparent pl-1 pb-2 pt-2 text-sm font-medium text-gray-500 hover:border-blue-500 hover:text-gray-700"
                             >

                                 <ArrowLongRightIcon className="ml-3 h-5 w-5 text-green-800" aria-hidden="true"/>
                             </div>

                         </div>
                         <div className="-mt-px flex w-0 flex-1 justify-end">
                             <div
                                     onClick={() => getMedia("", query,false)}
                                     className="cursor-pointer inline-flex items-center justify-end border-b-2 border-transparent pr-1 pb-2 pt-2 text-sm font-medium text-gray-500 hover:border-blue-500 hover:text-gray-700"
                             >
                                 <i className="refreshMedia fa-duotone fa-solid fa-repeat"></i>
                             </div>
                         </div>
                     </nav>}

                    <div className="mt-2">
                        {nothingFound && <div className="text-center text-gray-500 mt-2">No matching images found</div>}
                        <ul role="list" className={wide ? 'grid grid-cols-4' : 'grid grid-cols-2'}>

                            {mediaList.map((media) => (<li key={media.displayURL} className="relative p-1"
                                /*onClick={(e) => viewArtist(artist)}*/>
                                <div
                                    className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"

                                >
                                    <img
                                        src={'https://promogogo.com/cdn-cgi/image/width=400,height=300,fit=cover/' + media.displayURL}
                                        alt=""
                                        className="pointer-events-none object-cover group-hover:opacity-75 min-h-40"/>
                                    <button type="button"
                                            className="absolute inset-0 focus:outline-none"
                                            data-button={target} data-media={media.displayURL}
                                            data-unique={true} data-remove={false}>
                                    </button>
                                </div>
                                <div
                                    className={'text-sm font-semibold text-gray-900 mt-2'}>{media.sourceCaption}</div>
                            </li>))}
                        </ul>
                        <div className={'mt-2'}>
                            <MediaUploader/>
                        </div>
                    </div>
                </div>

            </div>

        </div>)
}
