import React, {Fragment, useEffect, useRef, useState} from 'react'
import axios, * as others from 'axios';
import {Link, useLoaderData, useLocation, useParams} from "react-router-dom";
import {
    addEventAttribute, adminVenueCard,
    changeAttribute,
    getAttribute,
    getImageWithPrefix,
    getLocalDate,
    hasAttribute,
    setAttribute,
    setRoomAttribute,
    setUniqueEventAttribute,
    smallArtistCard,
    smallEventCard,
    smallVenueCard,
    toastSuccess
} from "../../components/common/utilities";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import MainNavigation from "../../components/common/navigation.component";
import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import 'moment/locale/en-gb';
import SidebarNavigation from "../../components/common/sidebarnavigation.component";
import MediaCollection from "../media/mediaCollection";
import {Dialog, Field, Label, Switch, Transition} from "@headlessui/react";
import mapboxgl from "mapbox-gl";
import {Geocoder} from '@mapbox/mapbox-sdk';
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import Map, {GeolocateControl, Marker} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {ToastContainer} from "react-toastify";
import {XMarkIcon} from "@heroicons/react/24/outline";
import FindVenues from "../venues/findVenues";
import CreateEvent from "./createEvent";
import FindEvents from "./findEvents";
import NLPDashboard from "../nlp/nlpdash";

function classNames(...classes: any[])
{
    return classes.filter(Boolean).join(' ')
}

export default function CreateEvents() {
    let params = useParams();
    const venueID = params.venueID;
    const data = useLoaderData() as any;
    console.log("params", params);

    type SchemaEvent = {
        [key: string]: any; // ?? variable key
        name: string;
    };

    const text = useRef("");
    const inFocus = useRef("");
    const [artists, setArtists] = useState(data.artists);
    const [photos, setPhotos] = useState([]);
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#ffffff");
    const [date, setDate] = useState(null)
    const [venueData, setVenueData] = useState<SchemaEvent>();
    const [showMedia, setShowMedia] = useState(false);
    const [wholeDay, setWholeDay] = useState(false);
    const [lang, setLang] = useState(getLang());
    const [open, setOpen] = useState(false);

    function getLang()
    {
        if (navigator.languages != undefined) return navigator.languages[0];
        return navigator.language;
    }

    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

    console.log('access token', process.env.REACT_APP_MAPBOX_TOKEN);

    const getVenue = () => {

        let parameters = {
            instance: 'promogogo', ticket: localStorage.getItem('ticket'), venue: params.venueid, light: true, lang: lang
        };
        if (data.isAdmin)
        {
            parameters['admin'] = true;
        }

        axios.get(`${process.env.REACT_APP_API_URL}/p/api/venue.do`, {
            params: parameters

        }).then(function (response) {
            // handle success
            //console.log(response);
            setVenueData(response.data)
            if (response.data.geo && response.data.geo.latitude)
            {
                mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
                //setTimeout(() => renderMap(response.data.geo.latitude, response.data.geo.longitude), 1000);
            }


        });
    }


    const getEvents = nextOffset => {
        axios.get(`${process.env.REACT_APP_API_URL}/p/events/upcoming.do`, {
            params: {
                instance: 'promogogo', ticket: localStorage.getItem('ticket'), //guid: params.id,
                limit: 10, offset: nextOffset, artist: params.id,
            }

        }).then(function (response) {
            // handle success
            console.log("events", response);
            //setRooms(response.data.collection);

        });
    }

    const toggleMedia = () => {
        if (showMedia)
        {
            setShowMedia(false);
        }
        else
        {
            setShowMedia(true);
        }
    }

    const renderMap = (latitude, longitude) => {
        const map = new mapboxgl.Map({
                                         container: 'map',
                                         style: 'mapbox://styles/mapbox/streets-v11',
                                         center: [longitude, latitude],
                                         zoom: 14,
            interactive: true
                                     });
        map.on('click', function(e) {
            var coordinates = e.lngLat;
            setVenueData({
                             ...venueData, geo: {
                    latitude: coordinates.lat, longitude: coordinates.lng
                }
                         })
            new mapboxgl.Popup()
                .setLngLat(coordinates)
                .setHTML('you clicked here: <br/>' + coordinates)
                .addTo(map);
        });
        const startMarker = new mapboxgl.Marker()
            .setLngLat([longitude, latitude])
            .addTo(map);
    }


    useEffect(() => {

        //getVenue();
        //getPhotos();
        //handleArtists(data);

    }, []);

    const pagedata = useLoaderData() as any;
    let faceguid = '';

    function clickedEvent(e)
    {
        console.log("clicked", e.target);

        let button = e.target.dataset.button;
        if (button === 'mediaSelected')
        {
            console.log('settingMedia');
            let media = e.target.dataset.media;
            setVenueData({
                             ...venueData, image: media
                         });


            let key = e.target.dataset.key;
            let value = e.target.dataset.value;
            let unique = e.target.dataset.unique;
            let remove = e.target.dataset.remove;
            let eventid = e.target.dataset.eventid;

            //setUniqueEventAttribute(key,value,unique,remove,eventid);

            toastSuccess('Event updated');

        }
        else if (button === 'setPrivate')
        {
            console.log('setting private');
            let key = e.target.dataset.key;
            let value = e.target.dataset.value;
            let unique = e.target.dataset.unique;
            let remove = e.target.dataset.remove;
            let eventid = e.target.dataset.eventid;

            setUniqueEventAttribute(key, value, unique, remove, eventid);

            toastSuccess('Event set to private');

        }

        else if (button === 'editVenue')
        {
            console.log('loading venue');
            let venueid = e.target.dataset.venueid;
            window.location.href = '/venue/' + venueid;

        }

        else if (button === 'editAttraction')
        {
            console.log('loading attraction');
            let attractionid = e.target.dataset.attractionid;
            window.location.href = '/attraction/' + attractionid;

        }

        else if (button === 'editEvent')
        {
            console.log('loading event');
            let attractionid = e.target.dataset.attractionid;
            window.location.href = '/event/' + attractionid;

        }


    }
    function onSelected(viewport) {
        console.log(viewport);
        setVenueData({
                         ...venueData, geo: {
                latitude: viewport.latitude, longitude: viewport.longitude
            }
                     });
    }

    return (

        <div className="min-h-full dark:bg-white bg-white">
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-40" onClose={setOpen}>
                    <div className="fixed inset-0"/>

                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-2">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen sm:max-w-3xl xl:max-w-6xl">
                                        <div
                                            className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                                            <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll overflow-x-hidden py-1">
                                                <div className="px-2 sm:px-6">
                                                    <div className="flex items-start justify-between">
                                                        <Dialog.Title
                                                            className="text-base font-semibold leading-6 text-gray-900">
                                                            Action Panel
                                                        </Dialog.Title>
                                                        <div className="ml-3 flex h-7 items-center">
                                                            <button
                                                                type="button"
                                                                className="relative rounded-md bg-red-700 text-white hover:bg-red-500 hover:outline-none hover:ring-2 hobver:ring-red-500"
                                                                onClick={() => setOpen(false)}
                                                            >
                                                                <span className="absolute -inset-2.5"/>
                                                                <span className="sr-only">Close panel</span>
                                                                <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                                    <CreateEvent/>
                                                </div>
                                            </div>
                                            <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                                <button
                                                    type="button"
                                                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                                                    onClick={() => setOpen(false)}
                                                >
                                                    Close
                                                </button>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <SidebarNavigation/>
            <div className="lg:pl-72">

                <main>
                    <NLPDashboard/>

                </main>
                <footer className="bg-white" aria-labelledby="footer-heading">
                    <h2 id="footer-heading" className="sr-only">
                        Footer
                    </h2>

                </footer>
            </div>
            <ToastContainer autoClose={800} theme={'dark'}/>

        </div>
)
}
