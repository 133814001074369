import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import {bigArtistCard, MetaTags, promotedEventCard, smallEventCard, useLanguage} from "./components/common/utilities";
import axios from "axios";
import {ArrowLongLeftIcon, ArrowLongRightIcon, ChevronDoubleLeftIcon} from "@heroicons/react/20/solid";
import {Footer} from "./components/common/headerAndFooter";
import MainNavigation from "./components/common/navigation.component";

function App()
{
  const [promotedEvents, setPromotedEvents] = React.useState<any[]>([]);
  const [events, setEvents] = useState([]);
  const [artistEvents, setArtistEvents] = useState([]);
  const [edp, setEDP] = useState([]);
  const [eventOffset, setEventOffset] = useState(0);
  const [eventLimit, setEventLimit] = useState(10);
  const [nextOffset, setNextOffset] = useState("");
  const [previousOffset, setPreviousOffset] = useState("");
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [majortype, setMajorType] = useState("");
  const [audience, setAudience] = useState("");
  const [startDate, setStartDate] = useState("");
  const [country, setCountry] = useState("IS");
  const [isEnd, setIsEnd] = useState(false);
  const language = useLanguage();
  const [loading, setLoading] = useState(false);

  const stopBouncing = () => {
    const el = document.getElementById("promo-icon");
    if (el) {
      el.classList.remove("animate-bounce");
    }
  }


  const getPromotedEvents = (nextOffset: any) => {
    window.scrollTo(0, 0);
    setEvents([]);
    setLoading(true);
    axios.get(`https://data.promogogo.com/p/events/partnerfeed.do?site=gjugg&lang=is`).then(function (response) {
      setLoading(false);
      setPromotedEvents(response.data.collection);
      //console.log("events", response);
      //setRooms(response.data.collection);

    });
  }

  const getEvents = (nextOffset: any, majorcategory: any, filteraudience: any, startondate: any) => {
    window.scrollTo(0, 0);
    setEvents([]);
    setLoading(true);
    setPromotedEvents([]);
    let eventCountry = country;
    if(!startondate)
    {
        startondate = new Date().toISOString();
    }


    axios.get(`https://data.promogogo.com//p/api/events/forpartners.do`, {
      params: {
        partner: "38d059af4731e1ca2e53f4c3e2112656",
        limit: 96,
        country: eventCountry,
        offset: nextOffset,
        lang: "is",
        tiny: true,
        admin: true,
        majorcategory: majorcategory,
        audience: filteraudience,
        allattributes: true,
        startdate: startondate,
      }

    }).then(function (response) {
      // handle success
      console.log("events", response);
      setLoading(false);
      setEvents(response.data.collection);
      setNextOffset(response.data.pagination.nextOffset);
      setPreviousOffset(response.data.pagination.previousOffset);
      setIsEnd(response.data.pagination.end);
      setEventOffset(eventOffset + eventLimit);
    });
  }

  useEffect(() => {
    getPromotedEvents(0);
    //getEvents(0, majortype, audience, startDate);
    setTimeout(() => stopBouncing(), 3500)
    //getPhotos();

  }, []);

  const showPromotedEvents = () => {
    getPromotedEvents(0);
  }
  const showUpcoming= () => {
    getEvents(0, majortype, audience, startDate);
  }

  const showByType = (e: any) => {
    e.preventDefault();
    let type = e.target.value;
    if(type === "promoted")
    {
      getPromotedEvents(0);
      return;
    }
    setMajorType(type);
    getEvents("", type, audience, startDate);
  }

  const showByAudience = (e: any) => {
    e.preventDefault();
    let type = e.target.value;
    setAudience(type);
    getEvents("", majortype, type, startDate);
  }

  const changeStart = (e: any) => {
    console.log(e._d);
    var d = new Date(e._d);

    console.log(d.toISOString());
    setEvents([]);
    setStartDate(d.toISOString());
    getEvents("", majortype, audience, d.toISOString())
  }

  return (

      <><MetaTags
          title='Mobilitus'
          description='Gervigreind viðburðaskráning'
          image='https://media.promogogo.com/scripts/plugins/images/lattu-fleiri-vita.png'
          name='Mobilitus'/>
        <MainNavigation/>
        <div className={'bg-pg_blue px-6 py-4 sm:px-6 sm:py-10 lg:px-8 text-white'}>
        <div className="mx-auto max-w-2xl text-center mb-4">
          <div className="flex justify-center">
            <img id="promo-icon"
                 className="h-16 w-auto animate-bounce"
                 src="https://cached.promogogo.com/styles/promogogo/promo_heart_yellow_256px.png"
                 alt="Promogogo"/>
          </div>
          <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
            Allir viðburðir
            <br/>
            á öllum sviðum<br/>um allt land.
          </h2>
          {/*<div className={"mt-2 grid grid-cols-2"}>
            <div className={'col-span-2 flex justify-center'}>
              <h5 className={'text-md mb-3'}>Sæktu appið</h5>
            </div>
            <div className={'col-span-1 flex justify-center'}>
              <div>
                <a href={"https://apps.apple.com/app/id1569212120"}><img className={'w-40'}
                                                                         src={'https://media.promogogo.com/gjugg/2021-07-28/065b0812bb904530a5107855caa86953.png'}/>
                </a>
              </div>
            </div>
            <div className={'col-span-1 flex justify-center'}>
              <div>
                <a href={"https://play.google.com/store/apps/details?id=com.appgjugg"}><img className={'w-40'}
                                                                                            src={'https://media.promogogo.com/gjugg/2021-07-28/394c616211b9475fbb336cb93d4a4fd7.png'}/>
                </a>
              </div>
            </div>
          </div>*/}

        </div>
        </div>
        <div className="bg-white">
          <div className="mx-auto max-w-2xl text-center mb-4 pt-2">

            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
              Gervigreindarálfarnir okkar
              <br/>
              finna og kynna sér<br/>viðburði héðan og þaðan.
            </h2>
            <p className={'mt-5'}>
              Ef þér finnst þeir ekki vera að standa sig, þá getur þú potað í þá:
            </p>
            <div className="mt-10 flex items-top justify-center gap-x-6">
              <a
                  href="/findevent"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Finna viðburði
              </a>
              <a
                  href="/addevent"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Bæta viðburði við
              </a>
              <a
                  href="/admarket"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Auglýsa viðburði
              </a>

            </div>
            {/*<div className={"mt-2 grid grid-cols-2"}>
            <div className={'col-span-2 flex justify-center'}>
              <h5 className={'text-md mb-3'}>Sæktu appið</h5>
            </div>
            <div className={'col-span-1 flex justify-center'}>
              <div>
                <a href={"https://apps.apple.com/app/id1569212120"}><img className={'w-40'}
                                                                         src={'https://media.promogogo.com/gjugg/2021-07-28/065b0812bb904530a5107855caa86953.png'}/>
                </a>
              </div>
            </div>
            <div className={'col-span-1 flex justify-center'}>
              <div>
                <a href={"https://play.google.com/store/apps/details?id=com.appgjugg"}><img className={'w-40'}
                                                                                            src={'https://media.promogogo.com/gjugg/2021-07-28/394c616211b9475fbb336cb93d4a4fd7.png'}/>
                </a>
              </div>
            </div>
          </div>*/}

          </div>
          <div className="px-2 py-4 sm:px-6 sm:py-10 lg:px-2">


            {/*<div className="mt-10 flex items-top gap-x-6">

              <div>
                <div className={'text-gray-500 text-xs'}>Type</div>
                <div>
                  <select
                      onChange={(e) => showByType(e)}
                      className="rounded-md px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <option value="promoted">Promoted</option>
                    <option value="">All</option>
                    <option value={'arts'}>Arts</option>
                    <option value={'burlesque'}>Burlesque/Drag</option>
                    <option value={'community'}>Community</option>
                    <option value={'exhibition'}>Exhibitions</option>
                    <option value={'festival'}>Festivals</option>
                    <option value={'music'}>Music</option>
                    <option value={'sports'}>Sports</option>
                    <option value={'standup'}>Standup</option>
                    <option value={'theatre'}>Theatre</option>
                  </select>
                </div>
              </div>
              <div>
                <div className={'text-gray-500 text-xs'}>Audience:</div>
                <div>
                  <select
                      onChange={(e) => showByAudience(e)}
                      value={audience}
                      className="rounded-md px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <option value="">All</option>
                    <option value={'kids'}>Kids</option>
                    <option value={'elderly'}>Elderly</option>
                    <option value={'adults'}>Adults</option>
                    <option value={'touristfriendly'}>Tourist Friendly</option>
                    <option value={'hottickets'}>Hot Tickets</option>
                    <option value={'couples'}>Couples</option>
                    <option value={'singles'}>Singles</option>
                    <option value={'lgbtq'}>LGBTQ</option>
                    <option value={'families'}>Families</option>
                    <option value={'Parents'}>Parents</option>
                    <option value={'innovation'}>Innovation</option>
                    <option value={'foodies'}>Foodies</option>
                    <option value={'students'}>Students</option>
                    <option value={'musiclovers'}>Music Lovers</option>
                  </select>

                </div>

              </div>
            </div>*/}
            {loading && <div className="p-4 text-gray-800 flex justify-center">
              <div className={'text-pg_yellow'}><i
                  className="fa-2x fa-duotone fa-loader fa-spin"></i></div>
            </div>}
            {promotedEvents && promotedEvents.length > 0 && <>
              <div className={"grid grid-cols-2 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"}>
                {promotedEvents.map((event, index) => (<div key={event['@id']}>
                  {promotedEventCard(event)}
                </div>))}
              </div>
            </>}
            {events && events.length > 0 && <>
              <h1 className={"text-2xl font-bold text-gray-900 mt-3"}>Þau sem eru með viðburði næstu vikuna</h1>
              <nav
                  className="sticky bg-white z-30 flex items-center justify-between px-4 sm:px-0  mt-2 mb-2"
                  style={{top: "0px"}}>
                <div className="-mt-px flex w-0 flex-1">
                  <div
                      onClick={() => getEvents(0, majortype, audience, startDate)}
                      className="cursor-pointer inline-flex items-center border-b-2 border-transparent pr-1 pb-2 pt-2 text-sm font-medium text-gray-500 hover:border-blue-500 hover:text-gray-700"
                  >
                    <ChevronDoubleLeftIcon className="mr-3 h-5 w-5 text-green-800"
                                           aria-hidden="true"/>

                  </div>
                </div>
                <div className="-mt-px md:flex">
                  <div
                      onClick={() => getEvents(previousOffset, majortype, audience, startDate)}
                      className="cursor-pointer inline-flex items-center border-b-2 border-transparent pr-1 pb-2 pt-2 text-sm font-medium text-gray-500 hover:border-blue-500 hover:text-gray-700"
                  >
                    <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-green-800" aria-hidden="true"/>

                  </div>
                  {isEnd && <div
                      className="inline-flex items-center border-b-2 border-transparent pr-1 pb-2 pt-2 text-sm font-medium text-gray-500 hover:border-blue-500 hover:text-gray-700">
                    <ArrowLongRightIcon className="ml-3 h-5 w-5 text-green-800" aria-hidden="true"/>
                  </div>}
                  {!isEnd && <div
                      onClick={() => getEvents(nextOffset, majortype, audience, startDate)}
                      className="cursor-pointer inline-flex items-center border-b-2 border-transparent pl-1 pb-2 pt-2 text-sm font-medium text-gray-500 hover:border-blue-500 hover:text-gray-700"
                  >

                    <ArrowLongRightIcon className="ml-3 h-5 w-5 text-green-800" aria-hidden="true"/>
                  </div>}

                </div>
                <div className="-mt-px flex w-0 flex-1 ">
                </div>
              </nav>
              <div className={"grid grid-cols-2 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"}>
                {events.map((event, index) => (<div key={event['@id']}>
                  {promotedEventCard(event)}
                </div>))}
              </div>
            </>}
            <Footer/>

          </div>
        </div>
      </>

  );
}

export default App;
