import React, {Fragment, useEffect, useState} from 'react'
import {Dialog, Disclosure, Menu, Transition} from '@headlessui/react'
import {Bars3Icon, BellIcon, UsersIcon, XMarkIcon} from '@heroicons/react/24/outline'

import Propertylist from './facelist.component'
import PropertyNavigation from "../../components/common/navigation.component";
import Facelist from "./facelist.component";
import MainNavigation from "../../components/common/navigation.component";
import SidebarNavigation from "../../components/common/sidebarnavigation.component";
import {useLoaderData, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {
	addEventAttribute,
	adminEventCard,
	audienceButtons, MetaTags,
	setDefaultPerformer,
	setUniqueEventAttribute,
	smallEventCard,
	smallSchemaArtistCard,
	toastSuccess
} from "../../components/common/utilities";
import {ChevronDownIcon, MagnifyingGlassIcon, PencilSquareIcon} from "@heroicons/react/20/solid";
import {ArrowLongLeftIcon, ArrowLongRightIcon, ChevronDoubleLeftIcon} from "@heroicons/react/16/solid";

function classNames(...classes: any[]) {
	return classes.filter(Boolean).join(' ')
}

export default function UserDashboard() {
	const navigate = useNavigate();
	const logout = () => {
		window.location.href = '/logout';
	}

	let params = useParams();
	const venueID = params.venueID;
	const data = useLoaderData() as any;
	//console.log(data);

	const [events, setEvents] = useState([]);
	const [artistEvents, setArtistEvents] = useState([]);
	const [edp, setEDP] = useState([]);
	const [eventOffset, setEventOffset] = useState(0);
	const [eventLimit, setEventLimit] = useState(10);
	const [nextOffset, setNextOffset] = useState("");
	const [previousOffset, setPreviousOffset] = useState("");
	const [open, setOpen] = useState(false);
	const [teamList, setTeamList] = useState<any[]>([])
	const [inviteList, setInviteList] = useState<any[]>([])

	let [loading, setLoading] = useState(false);
	const [successMessage, setSuccessMessage] = useState(null);
	const [statusMessage, setStatusMessage] = useState(null);
	const [detailStatusMessage, setDetailStatusMessage] = useState(null);


	type SchemaArtist = {
		[key: string]: any; // ?? variable key
		name: string;
	};
const [artistData, setArtistData] = useState<SchemaArtist>();

	var date = new Date();

	function inviteMember(e)
	{
		e.currentTarget.disabled = true;
		e.preventDefault();
		const query = e.target.email.value;
		console.log('inviting member ', query);
		setLoading(true);
		setStatusMessage(null);
		setSuccessMessage(null);
		setDetailStatusMessage(null);
		axios.post(`${process.env.REACT_APP_API_URL}/p/user/sendinvite.do`, {
			guid: data.guid, ticket: localStorage.getItem('ticket'), email: query,
		}, {
					   headers: {
						   'Content-Type': 'application/x-www-form-urlencoded'
					   }
				   })
			.then(function (response) {
				// handle success
				//console.log(response);
				let location = response.data;
				console.log(location);
				setLoading(false);
				setSuccessMessage('Invitation sent to '+query);

			})
			.catch(function (error) {
				setLoading(false);
				console.log(error);

				setStatusMessage(error.message)
				setDetailStatusMessage(error.response.data.error)
			});


	}

	const getTeam = () =>
	{
			window.scrollTo(0, 0);
			//setEvents([]);


		axios.post(`${process.env.REACT_APP_API_URL}/p/user/teamlist.do`, {
			ticket: localStorage.getItem('ticket'),
			guid: data.guid
		}, {
					   headers: {
						   'Content-Type': 'application/x-www-form-urlencoded'
					   }
				   })
			.then(function (response) {
				// handle success
				//console.log(response);
				let location = response.data;
				console.log(location);
				setTeamList(response.data);

			})
			.catch(function (error) {
				console.log(error);
			});
	}

	const getInvited = () =>
	{
		//window.scrollTo(0, 0);
		//setEvents([]);


		axios.post(`${process.env.REACT_APP_API_URL}/p/user/invitelist.do`, {
			ticket: localStorage.getItem('ticket'),
			guid: data.guid
		}, {
					   headers: {
						   'Content-Type': 'application/x-www-form-urlencoded'
					   }
				   })
			.then(function (response) {
				// handle success
				//console.log(response);
				let location = response.data;
				console.log(location);
				setInviteList(response.data);

			})
			.catch(function (error) {
				console.log(error);
			});
	}

	const changeFace = (guid) =>
	{


		axios.post(`${process.env.REACT_APP_API_URL}/p/user/changeface.do`, {
			ticket: localStorage.getItem('ticket'),
			guid: guid
		}, {
					   headers: {
						   'Content-Type': 'application/x-www-form-urlencoded'
					   }
				   })
			.then(function (response) {
				// handle success
				console.log(response);
				if (response.data.name)
				{
					localStorage.setItem('username', response.data.name);
					window.location.href = '/dashboard';
				}


			})
			.catch(function (error) {
				console.log(error);
			});
	}

	const getArtistEvents = (nextOffset,artistid) =>
	{
		//window.scrollTo(0, 0);
		setArtistEvents([]);


		axios.get(`${process.env.REACT_APP_API_URL}/p/events/upcoming.do`, {
			params: {
				limit: 48,
				artist: artistid,
				offset: nextOffset,
				lang: "is",
				light: true,
			}

		}).then(function (response) {
			// handle success
			console.log("events",response);
			setArtistEvents(response.data.collection);

		});
	}



	useEffect(() => {

		getTeam();
		getInvited();
		//getPhotos();

	}, []);

	function clickedEvent(e)
	{
		console.log("clicked", e.target);

		let button = e.target.dataset.button;
		if(button === 'setAd')
		{
			console.log('setAd');
			let key = e.target.dataset.key;
			let value = e.target.dataset.value;
			let unique = e.target.dataset.unique;
			let remove = e.target.dataset.remove;
			let eventid = e.target.dataset.eventid;

			setUniqueEventAttribute(key,value,unique,remove,eventid);

			toastSuccess('Event updated');

		}
		else if(button === 'setPrivate')
		{
			console.log('setting private');
			let key = e.target.dataset.key;
			let value = e.target.dataset.value;
			let unique = e.target.dataset.unique;
			let remove = e.target.dataset.remove;
			let eventid = e.target.dataset.eventid;

			setUniqueEventAttribute(key,value,unique,remove,eventid);

			toastSuccess('Event set to private');

		}
		else if(button === 'viewEvent')
		{
			let eventid = e.target.dataset.eventid;
/*
			viewArtist(null,eventid);
*/
		}
		else if(button === 'setAttractionType')
		{
			console.log('setting private');
			let key = e.target.dataset.key;
			let value = e.target.dataset.value;
			let unique = e.target.dataset.unique;
			let remove = e.target.dataset.remove;
			let eventid = e.target.dataset.eventid;

			setUniqueEventAttribute(key,value,unique,remove,eventid);

			toastSuccess('Attraction Type set to '+value);

		}

		else if(button === 'addAttribute')
		{
			console.log('setting private');
			let key = e.target.dataset.key;
			let value = e.target.dataset.value;
			let unique = false;
			let remove = e.target.dataset.remove;
			let eventid = e.target.dataset.eventid;

			addEventAttribute(key,value,unique,eventid);

			toastSuccess('Attribute '+key+' set to '+value);

		}

		else if(button === 'manageAudience')
		{
			console.log('managing audience');
			let key = e.target.dataset.key;
			let value = e.target.dataset.value;
			let unique = false;
			let remove = e.target.dataset.remove;
			let eventid = e.target.dataset.eventid;

			addEventAttribute('pgg.audience.'+key,'pgg.audience.'+value,remove,eventid);

			toastSuccess('Audience set '+key+' Remove: '+remove);

		}

		else if(button === 'face')
		{
			let newguid = e.target.dataset.guid;
			console.log('changing face to ', newguid);

			changeFace(newguid);

		}

		/*let venueid = e.target.dataset.venueid;
        if (venueid !== undefined)
        {
            console.log(`Event bubbled up to div from `, venueid);
            //console.log(eventData);
            let venuename = e.target.dataset.venuename;
            let venueimage = e.target.dataset.venueimage;

            var locationData = eventData.location;
            locationData.name = venuename;
            locationData.image = venueimage;
            locationData['@id'] = venueid;

            setVenueData(locationData);

            setEventData({
                             ...eventData, location: {
                    locationData
                }

                         })
            console.log(eventData);

        }*/


	}

	const viewArtist = (artist) => {
		setArtistData(artist);
		setOpen(true);
		getArtistEvents("",artist['@id']);

	}


	return (<>
		<MetaTags
			title='User Dashboard'
			description='Auðveldaðu menningarumfjöllunina'
			image='https://promogogo.com/cdn-cgi/image/fit=contain,width=768/https://media.promogogo.com/ymsirvidburdir/2024-06-10/3666078aaa094262b626960bf705498b.png'
			name='Team Mobilitus'
		/>
		<div className="min-h-full dark:bg-white bg-white">
			<Transition.Root show={open} as={Fragment}>
				<Dialog as="div" className="relative z-40" onClose={setOpen}>
					<div className="fixed inset-0"/>

					<div className="fixed inset-0 overflow-hidden">
						<div className="absolute inset-0 overflow-hidden">
							<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-2">
								<Transition.Child
									as={Fragment}
									enter="transform transition ease-in-out duration-500 sm:duration-700"
									enterFrom="translate-x-full"
									enterTo="translate-x-0"
									leave="transform transition ease-in-out duration-500 sm:duration-700"
									leaveFrom="translate-x-0"
									leaveTo="translate-x-full"
								>
									<Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
										<div
											className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
											<div className="flex min-h-0 flex-1 flex-col overflow-y-scroll overflow-x-hidden py-1">

												<div className="relative mt-6 flex-1 px-4 sm:px-6">
													{artistData && artistData.name && <div className="px-4 py-5 sm:p-6" onClick={(event) => clickedEvent(event)}>
														{smallSchemaArtistCard(artistData)}
													</div>}
													{!artistData &&
													 <div className="p-4 text-gray-800 flex justify-center">
														 <div className={'text-pg_yellow'}><i
															 className="fa-2x fa-duotone fa-loader fa-spin"></i>
														 </div>
													 </div>}

													{artistData && <>
														<button onClick={() => setDefaultPerformer(artistData)} className={'bg-green-500 text-white p-2 m-2'}>Set as default performer</button>
													</>}

													{artistData && audienceButtons(artistData)}

													<h5 className="text-md font-semibold text-gray-900 mt-4">Events</h5>
													{artistEvents.length > 0 && artistEvents.map((event) => (
														<div className="px-4 py-5 sm:p-6" key={'artistevent'+event['@id']}>
															{smallEventCard(event)}
														</div>
													))}

												</div>
											</div>
											<div className="flex flex-shrink-0 justify-end px-4 py-4">
												<button
													type="button"
													className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
													onClick={() => setOpen(false)}
												>
													Close
												</button>
											</div>
										</div>
									</Dialog.Panel>
								</Transition.Child>
							</div>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
			<SidebarNavigation/>
			<div className="lg:pl-72">
				<div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
					<div className={'grid grid-cols-3 gap-4'}>
					    <div className={'col-span-1'}>
						<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
						Þú hefur aðgang að
						<br/>
						eftirfarandi síðum
					</h2>
					<div className="mt-10 flex items-center gap-x-6">
						<div  className=" font-semibold leading-6 text-gray-900">
							Þú getur haft aðgang að fleiri en einni síðu, með mismunandi greiðendum, án þess að þurfa að skrá þig inn aftur.
						</div>
					</div>
						</div>
						<div className={'col-span-2'}>
							<div className="mt-2">
								<h4 className={'text-lg font-semibold text-gray-900'}>Team Members for {data.name}</h4>
								{teamList.length > 0 && teamList.map((team) => (
									<div className="bg-white shadow overflow-hidden sm:rounded-md mb-4"
										 key={'team' + team.userGUID}>
										<div className="px-4 py-5 sm:px-6 flex justify-between items-center">
											<div className="flex items-center">
												<div className="ml-4">
													<div
														className="text-sm font-semibold text-gray-900">{team.firstName} {team.lastName}</div>
													<div className="text-sm text-gray-500">{team.loginName}</div>
												</div>
											</div>
											<div className="ml-5 flex-shrink-0">
												<button
													onClick={() => navigate('/dashboard/venue/' + team.userGUID)}
													className="font-semibold text-gray-900 hover:text-gray-700"
												>
													View
												</button>
											</div>
										</div>
									</div>))}
								<h4 className={'text-lg font-semibold text-gray-900'}>Invited to {data.name}</h4>
								{inviteList.length > 0 && inviteList.map((team) => (
									<div className="bg-white shadow overflow-hidden sm:rounded-md mb-4"
										 key={'team' + team.userGUID}>
										<div className="px-4 py-5 sm:px-6 flex justify-between items-center">
											<div className="flex items-center">
												<div className="ml-4">
													<div
														className="text-sm font-semibold text-gray-900">{team.firstName} {team.lastName}</div>
													<div className="text-sm text-gray-500">{team.loginName}</div>
												</div>
											</div>
											<div className="ml-5 flex-shrink-0">
												<button
													onClick={() => navigate('/dashboard/venue/' + team.userGUID)}
													className="font-semibold text-gray-900 hover:text-gray-700"
												>
													View
												</button>
											</div>
										</div>
									</div>))}
							</div>
							<div className="mt-2">
								<h4 className={'text-lg font-semibold text-gray-900'}>Invite someone to manage {data.name}</h4>

								<div className="mt-2">
									<form onSubmit={inviteMember}>
										<div>
											<label htmlFor="email"
												   className="block text-sm font-medium leading-6 text-gray-900">
												Enter email address
											</label>
											<div className="mt-2 flex rounded-md shadow-sm">
												<div
													className="relative flex flex-grow items-stretch focus-within:z-10">
													<div
														className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
														<UsersIcon className="h-5 w-5 text-gray-400"
																   aria-hidden="true"/>
													</div>
													<input
														type="email"
														name="email"
														id="email"
														className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
														placeholder="Email address"
													/>
												</div>
												<button
													type="submit"
													className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
												>
													Invite
												</button>
											</div>
											{successMessage && (

												<div className="text-center text-green-500 p-1">
													<span
														className="text-center text-green-500 p-1">{successMessage}</span>
												</div>)}
											{!statusMessage && (<div className="text-center text-red-500 p-1">
												{statusMessage && <span
													className="text-center text-red-500 p-1">{statusMessage}</span>}
												{detailStatusMessage && <span
													className="text-center text-red-500 p-1"><br/>{detailStatusMessage}</span>}
											</div>)}
										</div>
									</form>
								</div>


							</div>
						</div>
					</div>
				</div>
				<div className="p-1" onClick={(event) => clickedEvent(event)}>
					<div key={'addProperty'} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
						<div className="flex w-full items-center justify-between space-x-6 p-6">
							<div className="flex-1 truncate">
								<div className="flex items-center space-x-3">
									<h3 className="truncate text-sm font-medium text-gray-900">Stofna nýjan</h3>

								</div>
								<p className="mt-1 truncate text-sm text-gray-500">Fyrir auglýsanda eða viðburðahaldara.</p>
							</div>
						</div>
						<div>
							<div className="-mt-px flex divide-x divide-gray-200">
								<div className="flex w-0 flex-1" data-button={'createArtist'}>

									<div
										className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
									>
										<PencilSquareIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
										ARTIST
									</div>
								</div>
								<div className="flex w-0 flex-1">

									<div
										className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
									>
										<PencilSquareIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
										VENUE
									</div>
								</div>
								<div className="flex w-0 flex-1">

									<div
										className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
									>
										<PencilSquareIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
										MEDIA
									</div>
								</div>

							</div>
						</div>
					</div>

					<Facelist/>

				</div>
			</div>
		</div>


	</>)
}
