import React, {Fragment, useEffect, useState} from 'react'
import {Disclosure, RadioGroup, Tab, Menu, Transition} from '@headlessui/react'
import {
    BellIcon, StarIcon, FolderOpenIcon, PencilSquareIcon, CheckIcon, ChevronRightIcon, MagnifyingGlassIcon
} from '@heroicons/react/20/solid'
import {HeartIcon, MinusIcon, PlusIcon, XMarkIcon, Bars3Icon} from '@heroicons/react/24/outline'
import axios from "axios";
import {Link, useLocation} from "react-router-dom";
import PropertyNavigation from "../../components/common/navigation.component";
import {mergeVenues, thumbnailPad} from "../../components/common/utilities";
import {ArrowLongLeftIcon, ArrowLongRightIcon, ChevronDoubleLeftIcon} from "@heroicons/react/16/solid";
import AddressForm from "../../components/search/AddressSearch";


function classNames(...classes: any[])
{
    return classes.filter(Boolean).join(' ')
}

export default function FindVenues({venueData, limit, merge}) {
    const [venueList, setVenueList] = useState<any[]>([])
    const location = useLocation();
    const [searchingVenue, setSearchingVenue] = useState(false);
    const [totalVenues, setTotalVenues] = useState(0);
    const [venueName, setVenueName] = useState('');
    const [nextOffset, setNextOffset] = useState("");
    const [previousOffset, setPreviousOffset] = useState("");
    const [cancel, setCancel] = useState(null);
    const [mergeAllVenues, setMergeAllVenues] = useState(false);
    const [createVenue, setCreateVenue] = useState(false);

    useEffect(() => {

        //getEvents("");
        getVenue("");
        //getPhotos();

    }, []);

    function getVenue(offset)
    {
        let query = venueData ? venueData.name : '';
        if (!query)
        {
            query = localStorage.getItem('venue_name');
        }
        if (query)
        {
            setSearchingVenue(true);
            setVenueName(query);
            axios.get(`${process.env.REACT_APP_API_URL}/p/ajax/search/searchvenues.do?both=false&q=` + query + `&offset=` + offset + `&limit=` + limit, {})
                .then(function (response) {
                    // handle success
                    //console.log(response);
                    let location = response.data;
                    console.log(location);
                    setSearchingVenue(false);
                    setVenueList(location.collection);
                    setTotalVenues(response.data.pagination.totalSize)
                    setNextOffset(response.data.pagination.nextOffset);
                    setPreviousOffset(response.data.pagination.previousOffset);
                    return location;

                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                    return error;
                })
                .finally(function () {
                    return 'done';
                });


        }
        else return;
    }

    const mergeAll = () => {
        setMergeAllVenues(true);
        for (let i = 0; i < venueList.length; i++)
        {
            if (venueList[i].locationID !== venueData['@id'])
            {
                mergeVenues(venueData['@id'], venueList[i].locationID);
                setMergeAllVenues(false);
            }
        }
    }


    const sendData = () => {
        this.props.sendData('Data from child');
    }

    let cancelToken;

    function searchVenue(e: any, next, previous)
    {

        if (cancel)
        {
            cancel.cancel("Operation canceled due to new request.")
        }

        cancelToken = axios.CancelToken.source()
        setCancel(cancelToken);

        let query = "";
        if (e.currentTarget)
        {
            query = e.currentTarget.value;
        }
        else
        {
            query = venueName;
        }


        let params = {
            "ticket": localStorage.getItem('ticket'), "both": false, "q": query, "limit": limit,
        }
        if (next)
        {
            params['offset'] = next;
        }
        if (previous)
        {
            params['offset'] = previous;
        }
        if (query)
        {
            setSearchingVenue(true);
            setVenueName(query);
            localStorage.setItem('venue_name', query);
            try
            {
                const results = axios.get(`${process.env.REACT_APP_API_URL}/p/ajax/search/searchvenues.do`, {
                    params: params, cancelToken: cancelToken.token
                })
                    .then(function (response) {
                        // handle success
                        //console.log(response);
                        let location = response.data;
                        console.log(location);
                        setVenueList(location.collection);
                        setTotalVenues(response.data.pagination.totalSize)
                        setSearchingVenue(false);
                        setNextOffset(response.data.pagination.nextOffset);
                        setPreviousOffset(response.data.pagination.previousOffset);
                        return location;

                    })
                    .catch(function (error) {
                        // handle error
                        if (axios.isCancel(error))
                        {
                            console.log('Request canceled', error.message);
                        }
                        else
                        {
                            console.log(error);
                        }
                        return error;
                    })
                    .finally(function () {
                        return 'done';
                    });
            }
            catch (error)
            {

                if (axios.isCancel(error))
                {
                    console.log('Request canceled', error.message);
                }
                else
                {
                    console.log(error);
                }
            }


        }
        else return;
    }

    const getNext = () => {
        searchVenue("", nextOffset, "");
    }
    const getPrevious = () => {
        searchVenue("", "", previousOffset);
    }

    const getFirst = () => {
        searchVenue("", 0, "");

    }

    const clearSearch = () => {
        setVenueName('');
        localStorage.removeItem('venue_name');
        setVenueList([]);
        setTotalVenues(0);
    }

    return (

        <div className="min-h-full">
            <div className="bg-white ">
                {!createVenue && <>
                    <div className="mx-auto mt-6 max-w-2xl leading-8">
                        <div
                                className="relative flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <MagnifyingGlassIcon
                                    className="pointer-events-none absolute inset-y-0 left-1 h-full w-5 text-gray-400"
                                    aria-hidden="true"
                            />
                            <input
                                    type="text"
                                    name="venue_name"
                                    id="venue_name"
                                    className="block flex-1 border-0 bg-transparent  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 p-4 pl-8"
                                    placeholder="Venue"
                                    value={venueName}
                                    onChange={(e) => searchVenue(e, "", "")}
                            />
                            {venueName && <button
                                    type="button"
                                    className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    onClick={() => clearSearch()}
                            >
                                <i className="fa-solid fa-xmark"></i>
                            </button>}
                        </div>
                        <p className="mt-2 text-sm text-gray-500" id="email-description">
                            {totalVenues > 0 && totalVenues} {venueName && <>venues matching
                            "</>}{venueName}{venueName && <>".</>}
                            <div className="mt-2">
                                <div
                                        onClick={() => setCreateVenue(true)}
                                        className="cursor-pointer inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                >
                                    Not here? Create venue
                                </div>
                            </div>
                        </p>
                    </div>

                    <div className="mt-2">


                        <div className="mt-2">
                            <ul role="list" className="divide-y divide-gray-100">
                                {searchingVenue && <li className="p-4 text-gray-800 flex justify-center">
                                    <div className={'text-pg_yellow'}><i
                                            className="fa-2x fa-duotone fa-loader fa-spin"></i></div>
                                </li>}

                                {!searchingVenue && !merge && venueList.map((venue) => (<li key={venue.locationID}
                                                                                            className="relative py-5 hover:bg-gray-50 cursor-pointer">
                                    <div className="px-4 sm:px-6 lg:px-8" data-button={'editVenue'}
                                         data-venueid={venue.locationID} data-venuename={venue.name}
                                         data-venueimage={venue.bestImage}>
                                        <div className="mx-auto flex max-w-4xl justify-between gap-x-6">
                                            <div className="flex gap-x-4">
                                                <img
                                                    className="h-12 w-12 flex-none rounded-full bg-gray-50"
                                                    src={venue.bestImage} alt=""/>
                                                <div className="min-w-0 flex-auto">
                                                    <p className="text-sm font-semibold leading-6 text-gray-900">

                                                                        <span
                                                                            className="absolute inset-x-0 -top-px bottom-0"
                                                                            data-button={'editVenue'}
                                                                            data-venueid={venue.locationID}
                                                                            data-venuename={venue.name}
                                                                            data-venueimage={venue.bestImage}/>
                                                        {venue.name}

                                                    </p>
                                                    <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                                        {venue.address1}{venue.location && venue.location.city && <>, {venue.location.city}</>}{venue.location && venue.location.region && <> {venue.location.region}</>} {venue.location && venue.location.countryCode && <>&nbsp;<span className={'uppercase'}> {venue.location.countryCode}</span></>}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex items-center gap-x-4">
                                                <div
                                                    className="hidden sm:flex sm:flex-col sm:items-end">
                                                    {venueData && venue.locationID === venueData['@id'] &&
                                                     <CheckIcon className={'h-5 w-5 text-green-950'}/>}
                                                    <p className="text-sm leading-6 text-gray-900">{venue.majorCategory}</p>
                                                    {venue.isActive ? (<p className="mt-1 text-xs leading-5 text-gray-500">
                                                        Active
                                                    </p>) : (<div
                                                        className="mt-1 flex items-center gap-x-1.5">
                                                        <div
                                                            className="flex-none rounded-full bg-emerald-500/20 p-1">
                                                            <div
                                                                className="h-1.5 w-1.5 rounded-full bg-emerald-500"/>
                                                        </div>
                                                        <p className="text-xs leading-5 text-gray-500">Not
                                                            active</p>
                                                    </div>)}
                                                </div>
                                                <ChevronRightIcon
                                                    className="h-5 w-5 flex-none text-gray-400"
                                                    aria-hidden="true"/>
                                            </div>
                                        </div>
                                    </div>
                                </li>))}
                                {!searchingVenue && merge && venueList.map((venue) => (<li key={venue.id}
                                                                                           className="relative py-5 hover:bg-gray-50 cursor-pointer">
                                    <div className="p-2" >
                                        <div className="mx-auto grid grid-cols-3 gap-x-6">
                                            <div className="grid grid-cols-2 col-span-1  items-center">
                                                <div className={'col-span-1 m-2'}
                                                     data-button={'mergeAttraction'}
                                                     data-attractionid={venue.id}
                                                     data-attractionname={venue.displayname}
                                                     data-attractionimage={venue.bestImage}>
                                                    <i className="fa-duotone fa-solid fa-arrow-left"></i> Merge
                                                </div>
                                                <div className={'col-span-1 m-2 '}
                                                     data-button={'mergeWithThatAttraction'}
                                                     data-attractionid={venue.id}
                                                     data-attractionname={venue.displayname}
                                                     data-attractionimage={venue.bestImage}>
                                                    Merge <i
                                                    className="fa-duotone fa-solid fa-arrow-right"></i>
                                                </div>
                                            </div>
                                            <div className="flex gap-x-4 col-span-2"
                                                 data-button={'viewAttraction'}
                                                 data-attractionid={venue.id}
                                                 data-attractionname={venue.displayname}
                                                 data-attractionimage={venue.bestImage}
                                            >
                                                {venue.imageurl && thumbnailPad(venue.imageurl)}
                                                <div className="min-w-0 flex-auto">
                                                    <img
                                                        className="h-12 w-12 flex-none rounded-full bg-gray-50"
                                                        src={venue.bestImage} alt=""/>
                                                    <div className="min-w-0 flex-auto">
                                                        <p className="text-sm font-semibold leading-6 text-gray-900">

                                                                        <span
                                                                            className="absolute inset-x-0 -top-px bottom-0"
                                                                            data-button={'editVenue'}
                                                                            data-venueid={venue.locationID}
                                                                            data-venuename={venue.name}
                                                                            data-venueimage={venue.bestImage}/>
                                                            {venue.name}

                                                        </p>
                                                        <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                                            {venue.address1}{venue.location && venue.location && <>, {venue.location.city}</>}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </li>))}
                            </ul>
                            {venueList && venueList.length > 0 && <nav
                                    className="flex items-center justify-between border-t border-gray-200 px-4 py-2 sm:px-0  mt-2 mb-2 mr-4 ml-4"
                                    id={'topnav'}>
                                <div className="-mt-px flex w-0 flex-1">
                                    <div
                                            onClick={() => getFirst()}
                                            className="cursor-pointer inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-blue-300 hover:text-gray-700"
                                    >
                                        <ChevronDoubleLeftIcon className="mr-3 h-5 w-5 text-gray-400"
                                                               aria-hidden="true"/>

                                    </div>
                                </div>
                                <div className="flex">

                                    <div
                                            onClick={() => getPrevious()}
                                            className="cursor-pointer inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                    >
                                        <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400"
                                                           aria-hidden="true"/>
                                    </div>
                                    <div
                                            onClick={() => getNext()}
                                            className="cursor-pointer inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                    >
                                        <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400"
                                                            aria-hidden="true"/>
                                    </div>
                                </div>
                                <div className="-mt-px flex w-0 flex-1 justify-end">
                                    <div
                                            onClick={() => setCreateVenue(true)}
                                            className="cursor-pointer inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                                    >
                                        Not here? Create venue
                                    </div>
                                </div>
                            </nav>}
                            {merge && <div className={'mt-2'}>
                                <button onClick={mergeAll}
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-sm">Merge
                                    all {mergeAllVenues && <><i
                                            className="fa-2x fa-duotone fa-loader fa-spin text-sm"></i></>}
                                </button>
                            </div>}

                        </div>

                    </div>
                </>}
                {createVenue && <div className={'mt-2'}>
                    <AddressForm/>
                    <div
                            onClick={() => setCreateVenue(false)}
                            className="cursor-pointer inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                        Back to search
                    </div>
                </div>}
            </div>

        </div>)
}
