import React, {Fragment, useEffect, useRef, useState} from 'react'
import axios, * as others from 'axios';
import {useLoaderData, useLocation, useParams} from "react-router-dom";
import {
    addEventAttribute,
    adminEventCard,
    audienceButtons,
    changeAttribute,
    eventHeaderCard,
    getAttribute,
    getImageWithPrefix,
    getLocalDate,
    getLocalDateForEvent,
    hasAttribute,
    inheritAudiences,
    mergeEvents,
    mergeEventsCard,
    MetaTags,
    setAttribute,
    setRoomAttribute,
    setUniqueAttractionAttribute,
    setUniqueEventAttribute,
    smallArtistCard,
    smallEventCard,
    smallVenueCard, spinner,
    spotifyPlayer,
    toastSuccess,
    workingWithCard,
    workingWithCardPlusEdit
} from "../../components/common/utilities";
import moment from "moment";
import 'moment/locale/en-gb';
import {XMarkIcon} from "@heroicons/react/24/outline";
import {SchemaEvent} from "../../components/common/dataTypes";
import PromoteEvent from "../../components/common/promoteEvent";
import {Dialog, Field, Label, Switch, Transition} from "@headlessui/react";
import {ToastContainer} from "react-toastify";
import {Footer, Header} from "../../components/common/headerAndFooter";
import PromoteEventForUser from "../../components/common/promoteEventLoggedIn";


function classNames(...classes: any[])
{
    return classes.filter(Boolean).join(' ')
}

export default function ViewEvent() {
    let params = useParams();
    const venueID = params.venueID;
    const data = useLoaderData() as any;

    // console.log("params", params);

    const text = useRef("");
    const inFocus = useRef("");
    const [artists, setArtists] = useState();
    const [duplicates, setDuplicates] = useState([]);
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#ffffff");
    const [date, setDate] = useState(null)
    const [eventData, setEventData] = useState<SchemaEvent>();
    const [showMedia, setShowMedia] = useState(false);
    const [artistSearch, setArtistSearch] = useState(false);
    const [venueSearch, setVenueSearch] = useState(false);
    const [offers, setOffers] = useState([]);
    const [showDescriptions, setShowDescriptions] = useState(false);

    const [wholeDay, setWholeDay] = useState(false);
    const [isPrivate, setIsPrivate] = useState(false);
    const [lang, setLang] = useState(getLang());
    const [open, setOpen] = useState(false);
    const [showDetail, setShowDetail] = useState(false);

    function getLang()
    {
        if (navigator.languages != undefined) return navigator.languages[0];
        return navigator.language;
    }

    let adBtnGroupClasses = classNames('relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10', {});

    const getEvent = () => {

        const now = new Date();

        let parameters = {
            instance: 'promogogo', event: params.eventID, tiny: true, lang: lang, start: "now",
        };


        axios.get(`${process.env.REACT_APP_API_URL}/p/api/event.do`, {
            params: parameters

        }).then(function (response) {
            // handle success
            //console.log(response);
            setEventData(response.data)
            if (response.data.performers)
            {
                setArtists(response.data.performers);
            }
            if (response.data.gogo && response.data.gogo.wholeDay)
            {
                setWholeDay(response.data.gogo.wholeDay);
            }
            if (response.data.admin && response.data.admin.isprivate)
            {
                setIsPrivate(response.data.admin.isprivate);
            }
            if (response.data.offers)
            {
                setOffers(response.data.offers);
            }


        }).catch(function (error) {
            // handle error
            console.log(error);
            //window.location.href = '/dashboard';
        });
    }

    const getEvents = (nextOffset: any) => {
        axios.get(`${process.env.REACT_APP_API_URL}/p/events/upcoming.do`, {
            params: {
                instance: 'promogogo', ticket: localStorage.getItem('ticket'), //guid: params.id,
                limit: 10, offset: nextOffset, artist: params.id, lang: "is"
            }

        }).then(function (response) {
            // handle success
            console.log("events", response);
            //setRooms(response.data.collection);

        });
    }
    const getDuplicates = async () => {
        axios.post(`${process.env.REACT_APP_API_URL}/p/manage/event/duplicates.do`, {
            ticket: localStorage.getItem('ticket'), event: params.eventid,
        }, {
                       headers: {
                           'Content-Type': 'application/x-www-form-urlencoded'
                       }
                   }).then(function (response) {
            // handle success
            console.log(response);
            if (response.data.collection && response.data.collection.length > 1)
            {
                setDuplicates(response.data.collection);
            }

        });
    }

    const toggleMedia = () => {
        if (showMedia)
        {
            setShowMedia(false);
        }
        else
        {
            setShowMedia(true);
        }
    }

    const searchVenues = () => {
        setOpen(true);
        setVenueSearch(true);
        setArtistSearch(false);
        setShowDescriptions(false);

    }

    const searchAttractions = () => {
        setOpen(true);
        setVenueSearch(false);
        setArtistSearch(true);
        setShowDescriptions(false);

    }

    const editDescriptions = () => {
        setOpen(true);
        setVenueSearch(false);
        setArtistSearch(false);
        setShowDescriptions(true);
    }


    useEffect(() => {

        getEvent();
        //getDuplicates();
        //getPhotos();
        //handleArtists(data);

    }, []);


    const pagedata = useLoaderData() as any;
    let faceguid = '';

    // @ts-ignore
    function clickedEvent(e)
    {


    }


    return (

        <div className="min-h-full dark:bg-white bg-white">
            {eventData && <MetaTags
                    title={eventData.name + ' | Gjugg - allir viðburðir'}
                    description={"upplýsingar um " + eventData.name + " @ " + eventData.location.name}
                    image={eventData.image && eventData.image}/>}


            <div className="bg-pg_blue">
                <Header/>
                {!eventData && <div className={'bg-white rounded-lg mt-2 p-4'}>{spinner()}</div>}

                {eventData && <main>

                    <div className="p-2">
                        <div>
                            <div className="grid md:grid-cols-3">

                                {eventData && <>
                                    <div className="col-span-2 bg-white rounded-lg mt-2">

                                        <div className={''}>


                                            <div className="">

                                                <div className="p-2 sm:mt-16 lg:mt-0">
                                                    <div className="mb-2">
                                                        {eventData.image && (<img
                                                            className="w-100 flex-shrink-0 rounded-lg bg-gray-300 object-cover"
                                                            src={'https://promogogo.com/cdn-cgi/image/width=400,fit=cover/' + eventData.image}
                                                            alt=""/>)}
                                                    </div>
                                                    <h1 className="text-lg font-bold tracking-tight text-gray-900">
                                                        {eventData.name}</h1>
                                                    {eventData && eventData.gogo && eventData.gogo.wholeDay && (
                                                        <p className="truncate  text-gray-500">{getLocalDateForEvent(eventData, eventData.startDate, eventData.timezone)}</p>)}
                                                    {eventData && !eventData.gogo || !eventData.gogo.wholeDay && (
                                                        <p className="truncate text-gray-500">{getLocalDateForEvent(eventData, eventData.startDate, eventData.timezone)}</p>)}


                                                    {eventData && eventData.gogo && eventData.gogo.majorType &&
                                                     <div className={'mt-0 text-sm text-gray-500'}>
                                                         {eventData.gogo.majorType}
                                                     </div>}

                                                    {eventData.location &&
                                                     <div className=" px-4 sm:mt-16 sm:px-0 lg:mt-4">

                                                         {smallVenueCard(eventData.location)}
                                                     </div>}

                                                    {showDetail && <div className={'mt-4'}>

                                                    {eventData.description && <div className="mt-4"
                                                                                   dangerouslySetInnerHTML={{__html: eventData.description}}></div>}

                                                    <div className="p-2">
                                                        <div className="">
                                                            <div className="mt-4  sm:mt-0 sm:flex-none">
                                                                {eventData.url && <div>
                                                                    <a href={eventData.url} target="_blank">
                                                                        <button
                                                                                type="button"
                                                                                className="inline-flex w-full items-center rounded-md bg-pg_blue px-3 py-2 text-lg font-semibold text-white shadow-sm hover:bg-pg_pink focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                                                                        >
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                 fill="none" viewBox="0 0 24 24"
                                                                                 strokeWidth={1.5} stroke="currentColor"
                                                                                 className="size-6">
                                                                                <path strokeLinecap="round"
                                                                                      strokeLinejoin="round"
                                                                                      d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"/>
                                                                            </svg>
                                                                            <span className={'ml-2'}> Meiri upplýsingar</span>


                                                                        </button>
                                                                    </a>
                                                                </div>}
                                                                {eventData.offers && <>
                                                                    <div className="mt-4">

                                                                        <a href={eventData.offers.url} target="_blank">
                                                                            <button
                                                                                    type="button"
                                                                                    className="inline-flex w-full items-center rounded-md bg-pg_green px-3 py-2 text-xl font-semibold text-white shadow-sm hover:bg-pg_pink focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                                                                            >
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                     fill="none" viewBox="0 0 24 24"
                                                                                     strokeWidth={1.5}
                                                                                     stroke="currentColor"
                                                                                     className="size-6">
                                                                                    <path strokeLinecap="round"
                                                                                          strokeLinejoin="round"
                                                                                          d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z"/>
                                                                                </svg>
                                                                                <span className={'ml-2'}> Finna miða</span>
                                                                            </button>
                                                                        </a>
                                                                    </div>
                                                                </>}

                                                            </div>
                                                        </div>

                                                    </div>
                                                    </div>}

                                                </div>


                                            </div>
                                            <div className={'col-span-2'}>
                                                {localStorage.getItem('ticket') &&
                                                 <div className={'mt-1'}>
                                                     <PromoteEventForUser event={eventData}/>
                                                 </div>}
                                                {!localStorage.getItem('ticket') &&
                                                 <div className={'mt-1'}>
                                                     <PromoteEvent event={eventData}/>
                                                 </div>}

                                            </div>
                                        </div>
                                    </div>
                                    <div className={'col-span-1 m-3'}>
                                        <div className=" px-4 sm:mt-16 sm:px-0 lg:mt-0">
                                            <section aria-labelledby="details-heading" className="mt-12">

                                                <div>

                                                    {eventData.performers &&
                                                     <button className="text-white font-bold text-lg"
                                                     > Tengdir aðilar
                                                     </button>}
                                                    <div
                                                            className="mt-2">
                                                        {eventData.performers && eventData.performers.map((artist: any, index: number) => (<>
                                                            {workingWithCard(artist)}
                                                        </>))}


                                                    </div>

                                                    {eventData.performers && eventData.performers.map((artist: any, index: number) => (<>
                                                        {index === 0 &&
                                                         <div className={'mt-2'}>{spotifyPlayer(artist)}</div>}
                                                    </>))}


                                                </div>
                                            </section>

                                        </div>

                                    </div>

                                </>}
                            </div>

                        </div>
                        <div className="col-span-1">


                        </div>
                    </div>
                </main>}
                <Footer/>
            </div>
            <ToastContainer autoClose={800} theme={'dark'}/>

        </div>)
}
