import React, {Fragment, useEffect, useRef, useState} from 'react'
import {useLoaderData, useLocation, useParams} from "react-router-dom";

import { CheckIcon } from '@heroicons/react/20/solid'

import {DateTimePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import 'moment/locale/en-gb';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import axios from "axios";
import CheckoutForm from "./CheckoutForm";
import {getStripe} from "./utilities";



function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

// @ts-ignore
export default function PromoteEventForUser({event})  {
    let params = useParams();
    const venueID = params.venueID;
    const data = useLoaderData() as any;

    const stripePromise = getStripe();

    const [startDate, setStartDate] = useState(new Date());
    const [clientSecret, setClientSecret] = useState(null);


    let familyprice = 'price_1QFzO5EthSil1bykwsFTaKfw';
    let adultprice = 'price_1Q14w2EthSil1byk8RLsepOt';
    let businessprice = 'price_1Q14vzEthSil1bykVkroBCH5';

    let options:any;



    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
        familyprice = 'price_1Q14s8EthSil1bykC7uV1naC';
        adultprice = 'price_1Q14uMEthSil1bykvlp8gcDL';
        businessprice = 'price_1Q14vlEthSil1bykZvJ3wRYC';
    } else {
        console.log('prod server')

    }

    const tiers = [


        {
            name: 'Birting á Vísi',
            id: 'tier-freelancer',
            stripePriceID: familyprice,
            href: '#',
            priceMonthly: '12.900 kr.',
            priceamount: 12900,
            description: 'Magnaðu viðburðinn og hann mun birtast á forsíðu Vísis og Lífinu innan 10 mínútna.',
            features: '<span style="color: #1F2126; font-size: 20px; font-family: Berlingske Sans xtrabold, sans-serif; font-weight: 800; line-height: 26px; word-wrap: break-word; margin-right: 10px;"><img style=\"width: 17.5px; height: 20.5px; padding-top: 5px; color: #3B68BC; margin-right: 5px;\" src=\'https://media.promogogo.com/scripts/plugins/icons/check-sharp-solid.svg\'/>Forsíða Vísis</span> <span style="color: #1F2126; font-size: 20px; font-family: Berlingske Sans xtrabold, sans-serif; font-weight: 800; line-height: 26px; word-wrap: break-word"><img style=\"width: 17.5px; height: 20.5px; padding-top: 5px; color: #3B68BC; margin-right: 5px;\" src=\'https://media.promogogo.com/scripts/plugins/icons/check-sharp-solid.svg\'/>Forsíða Lífsins</span>',
            mostPopular: false,
        },
        /*{
            name: 'Fullorðnir',
            id: 'tier-startup',
            stripePriceID: adultprice,
            href: '#',
            priceMonthly: '9.300 kr.',
            description: 'Aukinn þungi settur á fréttasíður, en hinar með í bland.',
            features: [
                'Lífið', 'Forsíða'
            ],
            mostPopular: true,
        },
        {
            name: 'Bissness',
            id: 'tier-enterprise',
            stripePriceID: businessprice,
            href: '#',
            priceMonthly: '10.540 kr.',
            description: 'Viðskiptasíður og fréttir.',
            features: [
                'Viðskipti', 'Innherji', 'Forsíða'
            ],
            mostPopular: false,
        },
        {
            name: 'Sport',
            id: 'tier-sport',
            stripePriceID: businessprice,
            href: '#',
            priceMonthly: '10.540 kr.',
            description: 'Íþróttasíður og fréttir.',
            features: [
                'Sport'
            ],
            mostPopular: false,
        },*/
    ]
    let eventData = event;

    const createCheckoutSession =  (priceId: string) => {
        // Call your backend to create the Checkout session.
        axios.post(`${process.env.REACT_APP_API_URL}/p/payment/stripe/createcheckoutsession.do`, {
            ticket: localStorage.getItem('ticket'),
            event: params.eventid,
            price_id: priceId,
            embed: true,
            eventname: 'Auglýsing á Vísi.is fyrir ' + eventData.name ,
            site: "visir",
            successdest: `${process.env.REACT_APP_BASE_URL}/event/gjugg/` + eventData['@id'] ,
            canceldest: `${process.env.REACT_APP_BASE_URL}/event/gjugg/` + eventData['@id'],

        }, {
                       headers: {
                           'Content-Type': 'application/x-www-form-urlencoded'
                       }
                   }).then(function (response) {
            // handle success
            console.log(response);

            options = {
                // passing the client secret obtained from the server
                clientSecret: response.data.clientSecret,
                appearance: {
                    theme: 'stripe',
                    locale: 'en',
                },
                mode: 'payment',
            };

            let theClientSecret = response.data.clientSecret;
            //theClientSecret = theClientSecret.replace("cs_", "pi_");
            setClientSecret(theClientSecret);

            return null



        });
    }

    function headline() {
        let thePath = window.location.pathname;
        let theHeadline = '';
        if(thePath.includes('tilvalinn'))
        {
            theHeadline = 'Tilvalinn til kynningar';
        }
        else if(thePath.includes('allir-ad-koma'))
        {
            theHeadline = 'Ættu ekki allir að koma?';
        }
        else if(thePath.includes('fyllum-husid'))
        {
            theHeadline = 'Fyllum húsið!';
        }
        else if(thePath.includes('thjod-veit'))
        {
            theHeadline = 'Þjóð veit ekki þá þrír vita! Segðu þjóðinni frá.';
        }
        else if(thePath.includes('mamma-thin'))
        {
            theHeadline = 'Veit mamma þín af þessu?';
        }
        else {
            theHeadline = 'Ætti fólk ekki að vita af þessu?';
        }
        return theHeadline;
    }





    return (<div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-4xl text-center">
                    <h2 className="text-base font-semibold leading-7 text-indigo-600">Auglýstu!!</h2>
                    <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                        {headline()}
                    </p>
                </div>
                <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                    Birtu auglýsingar um þennan viðburð á Vísi.is og náðu betur til þeirra sem þú vilt.
                </p>
                <div
                    className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none ">


                    {tiers.map((tier, tierIdx) => <a
                        key={tier.id}
                        className={classNames(tier.mostPopular ? 'lg:z-10 lg:rounded-b-none' : 'lg:mt-8', tierIdx === 0 ? 'lg:rounded-r-none' : '', tierIdx === tiers.length - 1 ? 'lg:rounded-l-none' : '', 'flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10',)}
                    >
                        <div>
                            <div className="flex items-center justify-between gap-x-4">
                                <h3
                                    id={tier.id}
                                    className={classNames(tier.mostPopular ? 'text-indigo-600' : 'text-gray-900', 'text-lg font-semibold leading-8',)}
                                >
                                    {tier.name}
                                </h3>
                                {tier.mostPopular ?
                                 <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                                     Vinsælast
                                 </p> : null}
                            </div>
                            <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
                            <p className="mt-6 flex items-baseline gap-x-1">
                                    <span
                                        className="text-4xl font-bold tracking-tight text-gray-900">{tier.priceMonthly}</span>
                                <span className="text-sm font-semibold leading-6 text-gray-600"> /m.VSK</span>
                            </p>

                        </div>

                        {/*{clientSecret &&
                         <Elements stripe={stripePromise} options={{clientSecret: clientSecret, mode: 'payment'}}>
                             <CheckoutForm/>
                         </Elements>}*/}
                        <div className={'mt-8'}>
                        <form action={`${process.env.REACT_APP_API_URL}/p/payment/stripe/createcheckoutsession.do`}
                              method="POST">
                            <input type="hidden" name="site" value="gjugg"/>
                            <input type="hidden" name="eventname"
                                   value={'Auglýsing á Vísi.is fyrir ' + eventData.name}/>
                            <div className={'flex items-center justify-between gap-x-4'}>
                                <div className={'mt-4'}>
                            <LocalizationProvider dateAdapter={AdapterMoment}
                                                  adapterLocale="en-gb">
                                <DatePicker
                                    label="Frá og með"
                                    name={'startdate'}
                                    defaultValue={moment(startDate)}

                                    //onChange={}
                                />
                            </LocalizationProvider>
                                </div>
                                <div className={'md:col-span-1'}>
                                    <div className={'text-sm leading-6 text-gray-600 mt-0'}>Dagar</div>
                            <select name="quantity" id="quantity">
                                <option value="1">1 </option>
                                <option value="2">2 </option>
                                <option value="3">3 </option>
                                <option value="4">4 </option>
                                <option value="5">5 </option>
                                <option value="6">6 </option>
                                <option value="7">7 </option>
                                <option value="8">8 </option>
                            </select>
                                </div>
                            <input type="hidden" name="price_id"
                                   value={tier.stripePriceID}/>
                            {/* // Production servers
                                                                    <input type="hidden" name="price_id"
                                                                           value="price_1MqZiwEthSil1bykwOCSg2rm"/>*/}

                            <input type="hidden" name="event"
                                   value={eventData['@id']}/>
                            <input type="hidden" name="successdest"
                                   value={`${process.env.REACT_APP_BASE_URL}/event/gjugg/` + eventData['@id']}/>
                            <input type="hidden" name="canceldest"
                                   value={`${process.env.REACT_APP_BASE_URL}/event/gjugg/` + eventData['@id']}/>


                            <div className="mt-6">
                                <button
                                    className={classNames(tier.mostPopular ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500' : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300', 'block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',)}
                                    type="submit">
                                    Bóka
                                </button>
                            </div>
                            </div>
                        </form>
                        </div>
                    </a>)}
                </div>
            </div>
        </div>

    )
}
