import { EnvelopeIcon, PhoneIcon, FolderOpenIcon, PencilSquareIcon } from '@heroicons/react/20/solid'
import {Disclosure} from "@headlessui/react";
import {Link, useLoaderData, useLocation, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getAttribute} from "./utilities";

export default function MainNavigation() {
  const data = useLoaderData() as any;
  //console.log("PropertyNavigation", data);
  const location = useLocation();
  //console.log(location);
  let params = useParams();
  //console.log("PropertyNavigation", params);

  const [theNav,setTheNav] = useState<any[]>([
                                               {name: 'Dashboard', href: '/dashboard', current: true}])

  function classNames(...classes: any[])
  {
    return classes.filter(Boolean).join(' ')
  }

  let navigation = [
    {name: 'Dashboard', href: '/dashboard', current: false}]

  function picknav()
  {
    console.log('location.pathname',location.pathname);


     if(location.pathname.indexOf('view/artist') > -1)
     {
       setTheNav( [
         {name: 'Dashboard', href: '/dashboard', current: false}, {
           name: 'Artist', href: '/view/artist/'+getAttribute(data,"promo.custom.artist"), current: true
         },] )
     }
     else if(location.pathname.indexOf('onboarding') > -1)
     {
         setTheNav([
                       {name: 'Dashboard', href: '/dashboard', current: false}, {
                 name: 'Property', href: '/property/'+params.property, current: false
             },])
     }
     else if(location.pathname.indexOf('otaoverview') > -1)
     {
         setTheNav([
                       {name: 'Dashboard', href: '/dashboard', current: false}, {
                 name: 'Property', href: '/property/'+data.guid, current: false
             },{
                 name: 'Photos', href: '/property/'+data.guid+"/photos/", current: false
             },{
                 name: 'OTA overview', href: '/property/'+data.guid+'/otaoverview', current: true
             },])
     }
     else if(location.pathname.indexOf('photo') > -1)
     {
       setTheNav( [
                    {name: 'Dashboard', href: '/dashboard', current: false}, {
           name: 'Property', href: '/property/'+data.face.guid, current: false
         },
                    {
                      name: 'Photos', href: '/property/'+data.face.guid+"/photos/", current: false
                    },{
           name: 'Photo Details', href: '/property/'+data.face.guid+"/photo/"+data.guid, current: true
         },{
               name: 'OTA overview', href: '/property/'+data.face.guid+'/otaoverview', current: false
           },] )
     }
     else if(location.pathname.indexOf('room') > -1)
     {
         setTheNav( [
                        {name: 'Dashboard', href: '/dashboard', current: false}, {
                 name: 'Property', href: '/property/'+data.face.guid, current: false
             },
                        {
                 name: 'Room Details', href: '/property/'+data.face.guid+"/room/"+data.guid, current: true
             },{
                 name: 'OTA overview', href: '/property/'+data.face.guid+'/otaoverview', current: false
             },] )
     }
     else if(location.pathname.indexOf('findproperty') > -1)
     {
       setTheNav( [
                    {name: 'Dashboard', href: '/dashboard', current: false}, {
           name: 'Find Property', href: '/findproperty/', current: true
         },] )
     }
     else if(location.pathname.indexOf('newproperty') > -1)
     {
       setTheNav( [
                    {name: 'Dashboard', href: '/dashboard', current: false}, {
           name: 'Find Property', href: '/findproperty/', current: true
         }] )
     }

     else if(location.pathname.indexOf('property') > -1)
     {
       setTheNav( [
                    {name: 'Dashboard', href: '/dashboard', current: false}, {
           name: 'Property', href: '/property/'+data.guid, current: true
         },
                    {
                      name: 'Photos', href: '/property/'+data.guid+"/photos/", current: false
                    },{
               name: 'OTA overview', href: '/property/'+data.guid+'/otaoverview', current: false
           },] )
     }
  }

  useEffect(() => {

    picknav();

  }, []);


  const logout = () => {
    window.location.href = '/logout';
  }
  return (<Disclosure as="nav" className="bg-pg_blue gogonav">
      {({ open }) => (
          <>
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 justify-between">
                <div className="flex">

                  <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                    {theNav.map((item,index) => (
                        <a
                            key={"nav"+index}
                            href={item.href}
                            className={classNames(
                                item.current
                                ? 'border-pg_pink text-white'
                                : 'border-transparent text-white hover:border-pg_yellow hover:text-white',
                                'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </a>
                    ))}
                  </div>
                </div>
                <div className="sm:ml-6 sm:flex sm:items-center">
                  <div className="sm:ml-6 sm:flex sm:items-center">
                      <Link to={'/dashboard'} className={"text-white hover:text-pg_yellow"}><button
                          type="button"
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-pg_green hover:bg-pg_purple"
                      >Dashboard</button></Link>

                  </div>
                </div>
              </div>
            </div>
          </>
      )}
    </Disclosure>)
}
